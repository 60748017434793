@import './assets/fonts/noto/notosans.css';
:root {
  --pf-font-family-base: "Noto Sans", sans-serif;
  --pf-font-size-base: 1rem;
  --pf-font-size-h1: calc(1.5 * var(--pf-font-size-base));
  --pf-font-size-h2: calc(1.25 * var(--pf-font-size-base));
  --pf-font-size-h3: var(--pf-font-size-base);
  --pf-font-size-h4: calc(0.875 * var(--pf-font-size-base));
  --pf-font-size-h5: calc(0.75 * var(--pf-font-size-base));
  --pf-font-size-body: var(--pf-font-size-base);
  --pf-font-size-body2: calc(0.875 * var(--pf-font-size-base));
  --pf-font-size-caption: calc(0.875 * var(--pf-font-size-base));
  --pf-font-size-subtitle: var(--pf-font-size-base);
  --pf-font-size-subtitle2: calc(0.875 * var(--pf-font-size-base));
  --pf-font-size-button: var(--pf-font-size-base);
  --pf-font-size-overline: calc(0.75 * var(--pf-font-size-base));
  --pf-font-weight-thin: 100;
  --pf-font-weight-light: 300;
  --pf-font-weight-regular: 400;
  --pf-font-weight-medium: 500;
  --pf-font-weight-bold: 700;
  --pf-font-weight-heavy: 900;
  /* Labels */
  --pf-label-size: var(--pf-font-size-body);
  --pf-label-size-sm: var(--pf-font-size-overline);
  --pf-label-size-md: var(--pf-font-size-body);
  --pf-label-size-lg: var(--pf-font-size-h2);
  --pf-line-height-xs: calc(0.75 * var(--pf-font-size-base));
  --pf-line-height-sm: var(--pf-font-size-base);
  --pf-line-height-md: calc(1.25 * var(--pf-font-size-base));
  --pf-line-height-lg: calc(1.5 * var(--pf-font-size-base));
}

:root [data-theme=light] {
  --pf-primary-color: #6833d0;
  --pf-primary-color-100: #f0ebfa;
  --pf-primary-color-200: #d5c6f2;
  --pf-primary-color-300: #baa1e9;
  --pf-primary-color-400: #8c64db;
  --pf-primary-color-500: #6833d0;
  --pf-primary-color-600: #5e2ebb;
  --pf-primary-color-700: #5329a6;
  --pf-primary-color-800: #492492;
  --pf-primary-color-900: #3e1f7d;
  --pf-primary-color-1000: #271153;
  --pf-secondary-color: #0070f5;
  --pf-secondary-color-100: #dceafd;
  --pf-secondary-color-200: #b3d4fc;
  --pf-secondary-color-300: #7ab5fa;
  --pf-secondary-color-400: #4797f5;
  --pf-secondary-color-500: #0070f5;
  --pf-secondary-color-600: #0067e1;
  --pf-secondary-color-700: #005ac4;
  --pf-secondary-color-800: #004eac;
  --pf-secondary-color-900: #004393;
  --pf-gray-color: #444444;
  --pf-gray-color-100: #eeeeee;
  --pf-gray-color-200: #d0d0d0;
  --pf-gray-color-300: #a9a9a9;
  --pf-gray-color-400: #737373;
  --pf-gray-color-500: #444444;
  --pf-gray-color-600: #373737;
  --pf-gray-color-700: #2c2c2c;
  --pf-gray-color-800: #1f1f1f;
  --pf-gray-color-900: #141414;
  --pf-steel-color: #7c8594;
  --pf-steel-color-100: #f4f6f8;
  --pf-steel-color-200: #d9dee5;
  --pf-steel-color-300: #bcc3cd;
  --pf-steel-color-400: #a0a9b5;
  --pf-steel-color-500: #7c8594;
  --pf-steel-color-600: #5f697a;
  --pf-steel-color-700: #454e5e;
  --pf-steel-color-800: #2d3444;
  --pf-steel-color-900: #1b202c;
  --pf-green-color: #008a00;
  --pf-green-color-100: #d6ecd6;
  --pf-green-color-200: #99d099;
  --pf-green-color-300: #66b966;
  --pf-green-color-400: #33a133;
  --pf-green-color-500: #008a00;
  --pf-green-color-600: #007c00;
  --pf-green-color-700: #006e00;
  --pf-green-color-800: #006100;
  --pf-green-color-900: #005300;
  --pf-orange-color: #ffa424;
  --pf-orange-color-100: #ffdfb2;
  --pf-orange-color-200: #ffd292;
  --pf-orange-color-300: #ffc471;
  --pf-orange-color-400: #ffb650;
  --pf-orange-color-500: #ffa424;
  --pf-orange-color-600: #f29c22;
  --pf-orange-color-700: #e69420;
  --pf-orange-color-800: #cc831d;
  --pf-orange-color-900: #b37319;
  --pf-red-color: #e72326;
  --pf-red-color-100: #ffd6d6;
  --pf-red-color-200: #f49ea0;
  --pf-red-color-300: #ef696b;
  --pf-red-color-400: #eb4649;
  --pf-red-color-500: #e72326;
  --pf-red-color-600: #d52023;
  --pf-red-color-700: #c21d20;
  --pf-red-color-800: #b01b1d;
  --pf-red-color-900: #9d181a;
  --pf-error-color: var(--pf-red-color-600);
  --pf-success-color: var(--pf-green-color-600);
  --pf-warning-color: var(--pf-orange-color-600);
  --pf-neutral-color: var(--pf-gray-color);
  --pf-info-color: var(--pf-secondary-color-600);
  --pf-link-color: var(--pf-secondary-color);
  --pf-link-hover-color: var(--pf-secondary-color-400);
}

:root [data-theme=dark],
:root {
  --pf-primary-color: #394c6b;
  --pf-primary-color-50: #bcc7da;
  --pf-primary-color-100: #7989b5;
  --pf-primary-color-200: #8593b3;
  --pf-primary-color-300: #7488a4;
  --pf-primary-color-400: #5c708c;
  --pf-primary-color-500: #394c6b;
  --pf-primary-color-600: #233346;
  --pf-primary-color-700: #182432;
  --pf-primary-color-800: #101a26;
  --pf-primary-color-900: #141b24;
  --pf-primary-color-950: #0c141d;
  --pf-secondary-color: #198bdc;
  --pf-secondary-color-50: #bddffa;
  --pf-secondary-color-100: #83c5f6;
  --pf-secondary-color-200: #68afee;
  --pf-secondary-color-300: #66a2d5;
  --pf-secondary-color-400: #36a1ea;
  --pf-secondary-color-500: #198bdc;
  --pf-secondary-color-550: #0070f5;
  --pf-secondary-color-600: #2f6a98;
  --pf-secondary-color-700: #0c6ebd;
  --pf-secondary-color-800: #0b5899;
  --pf-secondary-color-900: #002c4e;
  --pf-secondary-color-950: #112341;
  --pf-tertiary-color: #40464e;
  --pf-tertiary-color-50: #dae3eb;
  --pf-tertiary-color-100: #c2d4df;
  --pf-tertiary-color-200: #a3b2c9;
  --pf-tertiary-color-300: #8593b3;
  --pf-tertiary-color-400: #656f82;
  --pf-tertiary-color-450: #4d5667;
  --pf-tertiary-color-500: #40464e;
  --pf-tertiary-color-600: #334154;
  --pf-tertiary-color-700: #3b415b;
  --pf-tertiary-color-800: #344152;
  --pf-tertiary-color-900: #2d3444;
  --pf-tertiary-color-950: #283243;
  --pf-gray-color: #525252;
  --pf-gray-color-50: #f6f6f6;
  --pf-gray-color-100: #efefef;
  --pf-gray-color-200: #dcdcdc;
  --pf-gray-color-300: #bdbdbd;
  --pf-gray-color-400: #989898;
  --pf-gray-color-450: #737373;
  --pf-gray-color-500: #525252;
  --pf-gray-color-600: #404040;
  --pf-gray-color-700: #262626;
  --pf-gray-color-800: #171717;
  --pf-gray-color-900: #0a0a0a;
  --pf-red-color: #ce6068;
  --pf-red-color-50: #fcf5f4;
  --pf-red-color-100: #fae9e9;
  --pf-red-color-200: #f4d7d8;
  --pf-red-color-300: #ebb6b8;
  --pf-red-color-400: #df8d91;
  --pf-red-color-500: #ce6068;
  --pf-red-color-600: #b94553;
  --pf-red-color-700: #9b3544;
  --pf-red-color-800: #822f3e;
  --pf-red-color-900: #702b39;
  --pf-red-color-950: #3e131b;
  --pf-orange-color: #f46325;
  --pf-orange-color-50: #fff5ed;
  --pf-orange-color-100: #fee9d6;
  --pf-orange-color-200: #fcceac;
  --pf-orange-color-300: #faac77;
  --pf-orange-color-400: #f67e41;
  --pf-orange-color-500: #f46325;
  --pf-orange-color-600: #e54311;
  --pf-orange-color-700: #be3010;
  --pf-orange-color-800: #972815;
  --pf-orange-color-900: #792315;
  --pf-orange-color-950: #420f08;
  --pf-yellow-color: #f29c22;
  --pf-yellow-color-50: #fbf7eb;
  --pf-yellow-color-100: #f5edcc;
  --pf-yellow-color-200: #ecd99c;
  --pf-yellow-color-300: #deb650;
  --pf-yellow-color-400: #d7a438;
  --pf-yellow-color-500: #f29c22;
  --pf-yellow-color-600: #c88e2a;
  --pf-yellow-color-700: #ac6e22;
  --pf-yellow-color-800: #80670c;
  --pf-yellow-color-900: #463703;
  --pf-yellow-color-950: #312602;
  --pf-green-color: #15b77d;
  --pf-green-color-50: #a9f1ce;
  --pf-green-color-100: #71e4b3;
  --pf-green-color-200: #14b8a6;
  --pf-green-color-300: #03aaaa;
  --pf-green-color-400: #38cf94;
  --pf-green-color-500: #15b77d;
  --pf-green-color-600: #099263;
  --pf-green-color-700: #087950;
  --pf-green-color-800: #085d43;
  --pf-green-color-900: #084c38;
  --pf-green-color-950: #032b20;
  --pf-purple-color: #9776d3;
  --pf-purple-color-50: #f7f6fc;
  --pf-purple-color-100: #f1edfa;
  --pf-purple-color-200: #e4dff5;
  --pf-purple-color-300: #cfc4ee;
  --pf-purple-color-400: #b6a3e2;
  --pf-purple-color-500: #9776d3;
  --pf-purple-color-600: #8b60c7;
  --pf-purple-color-700: #7a4eb3;
  --pf-purple-color-800: #664196;
  --pf-purple-color-900: #55377b;
  --pf-purple-color-950: #352253;
  --pf-white-color: #ffffff;
  --pf-white-color-1: rgba(255, 255, 255, 0.01);
  --pf-white-color-3: rgba(255, 255, 255, 0.03);
  --pf-white-color-5: rgba(255, 255, 255, 0.05);
  --pf-white-color-10: rgba(255, 255, 255, 0.1);
  --pf-white-color-15: rgba(255, 255, 255, 0.15);
  --pf-white-color-20: rgba(255, 255, 255, 0.2);
  --pf-white-color-30: rgba(255, 255, 255, 0.3);
  --pf-white-color-40: rgba(255, 255, 255, 0.4);
  --pf-white-color-50: rgba(255, 255, 255, 0.5);
  --pf-white-color-60: rgba(255, 255, 255, 0.6);
  --pf-white-color-70: rgba(255, 255, 255, 0.7);
  --pf-white-color-80: rgba(255, 255, 255, 0.8);
  --pf-white-color-90: rgba(255, 255, 255, 0.9);
  --pf-white-color-100: rgba(255, 255, 255, 1);
  --pf-error-color: var(--pf-red-color);
  --pf-success-color: var(--pf-green-color);
  --pf-warning-color: var(--pf-yellow-color-300);
  --pf-neutral-color: var(--pf-gray-color-100);
  --pf-info-color: var(--pf-secondary-color-200);
  --pf-brand-color: var(--pf-secondary-color-550);
  --pf-link-color: var(--pf-gray-color-300);
  --pf-link-hover-color: var(--pf-gray-color-100);
}

:root {
  --pf-icon-size-xs: 0.6rem;
  --pf-icon-size-sm: 0.75rem;
  --pf-icon-size-md: 1rem;
  --pf-icon-size-lg: 1.5rem;
  --pf-icon-size-xl: 2rem;
}

:root {
  --pf-border-thin: thin;
  --pf-border-sm: 1px;
  --pf-border-md: 2px;
  --pf-border-lg: 4px;
  --pf-border-xl: 8px;
  --pf-rounded: 4px;
  --pf-rounded-0: 0px;
  --pf-rounded-sm: 2px;
  --pf-rounded-md: 4px;
  --pf-rounded-lg: 8px;
  --pf-rounded-xl: 24px;
  --pf-rounded-pill: 9999px;
  --pf-rounded-circle: 50%;
  --pf-rounded-shaped: 24px 0;
}

:root [data-theme=light] {
  --pf-border-color: var(--pf-gray-color-300);
  --pf-border-color-light: var(--pf-gray-color-100);
  --pf-horizontal-line-color: var(--pf-gray-color-200);
}

:root [data-theme=dark],
:root {
  --pf-horizontal-line-color: var(--pf-tertiary-color-800);
  --pf-border-color: var(--pf-tertiary-color-800);
  --pf-border-color-medium: var(--pf-tertiary-color-500);
  --pf-border-color-light: var(--pf-white-color-100);
}

:root {
  --pf-padding-0: 0;
  --pf-padding-0-5: 2px;
  --pf-padding-1: 4px;
  --pf-padding-1-5: 6px;
  --pf-padding-2: 8px;
  --pf-padding-2-5: 10px;
  --pf-padding-3: 12px;
  --pf-padding-3-5: 14px;
  --pf-padding-4: 16px;
  --pf-padding-5: 20px;
  --pf-padding-6: 24px;
  --pf-padding-7: 28px;
  --pf-padding-8: 32px;
  --pf-padding-9: 36px;
  --pf-padding-10: 40px;
  --pf-padding-11: 44px;
  --pf-padding-12: 48px;
  --pf-padding-13: 52px;
  --pf-padding-14: 56px;
  --pf-padding-15: 60px;
  --pf-padding-16: 64px;
}

:root {
  --pf-margin-auto: auto;
  --pf-margin-0: 0;
  --pf-margin-1: 4px;
  --pf-margin-2: 8px;
  --pf-margin-3: 12px;
  --pf-margin-4: 16px;
  --pf-margin-5: 20px;
  --pf-margin-6: 24px;
  --pf-margin-7: 28px;
  --pf-margin-8: 32px;
  --pf-margin-9: 36px;
  --pf-margin-10: 40px;
  --pf-margin-11: 44px;
  --pf-margin-12: 48px;
  --pf-margin-13: 52px;
  --pf-margin-14: 56px;
  --pf-margin-15: 60px;
  --pf-margin-16: 64px;
  --pf-negative-margin-1: -4px;
  --pf-negative-margin-2: -8px;
  --pf-negative-margin-3: -12px;
  --pf-negative-margin-4: -16px;
  --pf-negative-margin-5: -20px;
  --pf-negative-margin-6: -24px;
  --pf-negative-margin-7: -28px;
  --pf-negative-margin-8: -32px;
  --pf-negative-margin-9: -36px;
  --pf-negative-margin-10: -40px;
  --pf-negative-margin-11: -44px;
  --pf-negative-margin-12: -48px;
  --pf-negative-margin-13: -52px;
  --pf-negative-margin-14: -56px;
  --pf-negative-margin-15: -60px;
  --pf-negative-margin-16: -64px;
}

:root {
  --pf-size-0: 0;
  --pf-size-1: 4px;
  --pf-size-2: 8px;
  --pf-size-3: 12px;
  --pf-size-4: 16px;
  --pf-size-5: 20px;
  --pf-size-6: 24px;
  --pf-size-7: 28px;
  --pf-size-8: 32px;
  --pf-size-9: 36px;
  --pf-size-10: 40px;
  --pf-size-11: 44px;
  --pf-size-12: 48px;
  --pf-size-13: 52px;
  --pf-size-14: 56px;
  --pf-size-15: 60px;
  --pf-size-16: 64px;
}

:root,
:root [data-theme=light],
:root [data-theme=dark] {
  --pf-dropshadow: 5px 0 10px rgba(0, 0, 0, 0.3);
  --pf-focus-box-shadow-alignment: 0px 0px 0px 4px;
}

:root [data-theme=light] {
  --pf-white-color: #ffffff;
  --pf-black-color: #000000;
  --pf-background-color-light: var(--pf-gray-color-100);
  --pf-background-color: var(--pf-white-color);
  --pf-background-color-dark: var(--pf-gray-color-300);
  --pf-background-secondary-color-light: var(--pf-secondary-color-300);
  --pf-background-color-secondary: var(--pf-secondary-color-500);
  --pf-background-color-secondary-dark: var(--pf-secondary-color-900);
  --pf-font-color: var(--pf-gray-color);
  --pf-font-color-emphasis: var(--pf-gray-color-800);
  --pf-font-color-light: var(--pf-gray-color-400);
  --pf-font-color-light-header: var(--pf-primary-color-100);
  --pf-link-color: var(--pf-primary-color);
  --pf-active-link-color: var(--pf-primary-color-600);
}

:root [data-theme=dark],
:root {
  --pf-white-color: #ffffff;
  --pf-black-color: #000000;
  --pf-background-color-light: var(--pf-primary-color);
  --pf-background-color: var(--pf-primary-color-800);
  --pf-background-color-dark: var(--pf-primary-color-900);
  --pf-background-secondary-color-light: var(--pf-secondary-color-300);
  --pf-background-color-secondary: var(--pf-secondary-color-500);
  --pf-background-color-secondary-dark: var(--pf-secondary-color-950);
  --pf-font-color: var(--pf-white-color);
  --pf-font-color-emphasis: var(--pf-gray-color-300);
  --pf-font-color-light: var(--pf-gray-color-100);
  --pf-font-color-light-header: var(--pf-primary-color-100);
  --pf-link-color: var(--pf-gray-color-300);
  --pf-active-link-color: var(--pf-gray-color-100);
}

html,
body {
  padding: 0;
  margin: 0;
  color: var(--pf-font-color);
  font-size: var(--pf-font-size-base);
  font-family: var(--pf-font-family-base);
  font-weight: var(--pf-font-weight-regular);
}

a,
.link {
  color: var(--pf-link-color);
  transition: color 0.3s ease-in-out;
}
a:hover,
.link:hover {
  color: var(--pf-active-link-color);
}

.theme {
  background-color: var(--pf-background-color);
}

:root,
:root [data-theme=light],
:root [data-theme=dark] {
  --pf-button-font-weight: var(--pf-font-weight-regular);
  --pf-button-font-size-xs: calc(0.875 * var(--pf-font-size-base));
  --pf-button-font-size-sm: calc(0.875 * var(--pf-font-size-base));
  --pf-button-font-size-md: calc(0.875 * var(--pf-font-size-base));
  --pf-button-font-size-lg: var(--pf-font-size-base);
  --pf-button-line-height-xs: var(--pf-line-height-md);
  --pf-button-line-height-sm: var(--pf-line-height-md);
  --pf-button-line-height-md: var(--pf-line-height-md);
  --pf-button-line-height-lg: var(--pf-line-height-lg);
  --pf-button-border-radius: var(--pf-rounded-lg);
  --pf-button-pill-border-radius: 100px;
  --pf-button-focus-box-shadow: var(--pf-focus-box-shadow-alignment) rgba(25, 139, 220, 0.25);
  --pf-button-solid-focus-box-shadow: var(--pf-button-focus-box-shadow);
  --pf-button-action-focus-box-shadow: var(--pf-button-focus-box-shadow);
  --pf-button-outline-focus-box-shadow: var(--pf-focus-box-shadow-alignment)
    rgba(54, 161, 234, 0.25);
  --pf-button-soft-focus-box-shadow: var(--pf-focus-box-shadow-alignment) rgba(92, 112, 140, 0.25);
  --pf-button-destructive-focus-box-shadow: var(--pf-focus-box-shadow-alignment)
    rgba(206, 96, 104, 0.25);
}

:root [data-theme=light] {
  --pf-button-solid-background-color: var(--pf-primary-color);
  --pf-button-solid-color: var(--pf-white-color);
  --pf-button-solid-hover-background-color: var(--pf-primary-color-400);
  --pf-button-solid-focus-background-color: var(--pf-primary-color);
  --pf-button-solid-disabled-background-color: var(--pf-primary-color-300);
  --pf-button-solid-disabled-color: var(--pf-gray-color-100);
  --pf-button-outline-color: var(--pf-primary-color);
  --pf-button-outline-border-color: var(--pf-primary-color);
  --pf-button-outline-hover-color: var(--pf-primary-color-400);
  --pf-button-outline-disabled-color: var(--pf-primary-color-300);
  --pf-button-link-color: var(--pf-primary-color);
  --pf-button-link-hover-color: var(--pf-primary-color-600);
  --pf-button-link-disabled-color: var(--pf-primary-color-300);
  --pf-button-action-color: var(--pf-primary-color);
  --pf-button-action-border-color: var(--pf-primary-color);
  --pf-button-action-hover-background-color: var(--pf-primary-color-200);
  --pf-button-action-hover-border-color: var(--pf-primary-color-400);
  --pf-button-action-hover-color: var(--pf-primary-color);
  --pf-button-action-disabled-border-color: var(--pf-primary-color-300);
  --pf-button-action-disabled-color: var(--pf-primary-color-300);
  --pf-button-destructive-background-color: var(--pf-error-color);
  --pf-button-destructive-color: var(--pf-white-color);
  --pf-button-destructive-hover-background-color: var(--pf-red-color-400);
  --pf-button-destructive-hover-color: var(--pf-white-color);
  --pf-button-destructive-focus-background-color: var(--pf-red-color);
  --pf-button-destructive-focus-color: var(--pf-white-color);
  --pf-button-destructive-disabled-background-color: var(--pf-red-color-300);
  --pf-button-destructive-disabled-color: var(--pf-white-color);
  --pf-button-soft-color: var(--pf-primary-color-300);
  --pf-button-soft-border-color: var(--pf-primary-color-300);
  --pf-button-soft-hover-color: var(--pf-primary-color-600);
  --pf-button-soft-hover-border-color: var(--pf-primary-color-600);
  --pf-button-soft-focus-color: var(--pf-primary-color);
  --pf-button-soft-focus-border-color: var(--pf-primary-color);
  --pf-button-soft-disabled-color: var(--pf-primary-color-300);
  --pf-button-soft-disabled-border-color: var(--pf-primary-color-300);
}

:root [data-theme=dark] {
  --pf-button-solid-background-color: var(--pf-secondary-color);
  --pf-button-solid-color: var(--pf-white-color);
  --pf-button-solid-hover-background-color: var(--pf-secondary-color-550);
  --pf-button-solid-focus-background-color: var(--pf-secondary-color);
  --pf-button-solid-disabled-background-color: var(--pf-tertiary-color-600);
  --pf-button-solid-disabled-color: var(--pf-tertiary-color-400);
  --pf-button-outline-color: var(--pf-secondary-color-300);
  --pf-button-outline-border-color: var(--pf-secondary-color-300);
  --pf-button-outline-hover-color: var(--pf-secondary-color-200);
  --pf-button-outline-disabled-color: var(--pf-tertiary-color-450);
  --pf-button-link-color: var(--pf-secondary-color-550);
  --pf-button-link-hover-color: var(--pf-secondary-color-800);
  --pf-button-link-disabled-color: var(--pf-primary-color-200);
  --pf-button-action-color: var(--pf-secondary-color-300);
  --pf-button-action-border-color: #42a7ee;
  --pf-button-action-hover-background-color: var(--pf-tertiary-color-450);
  --pf-button-action-hover-border-color: #455770;
  --pf-button-action-hover-color: var(--pf-white-color);
  --pf-button-action-disabled-border-color: var(--pf-tertiary-color-400);
  --pf-button-action-disabled-color: var(--pf-tertiary-color-400);
  --pf-button-destructive-background-color: var(--pf-error-color);
  --pf-button-destructive-color: var(--pf-white-color);
  --pf-button-destructive-hover-background-color: var(--pf-red-color-600);
  --pf-button-destructive-hover-color: var(--pf-white-color);
  --pf-button-destructive-focus-background-color: var(--pf-red-color);
  --pf-button-destructive-disabled-background-color: var(--pf-red-color-900);
  --pf-button-destructive-disabled-color: var(--pf-white-color-40);
  --pf-button-soft-color: var(--pf-primary-color-400);
  --pf-button-soft-border-color: var(--pf-primary-color-400);
  --pf-button-soft-hover-color: var(--pf-primary-color-300);
  --pf-button-soft-hover-border-color: var(--pf-primary-color-300);
  --pf-button-soft-focus-color: var(--pf-primary-color-400);
  --pf-button-soft-disabled-color: var(--pf-tertiary-color-700);
  --pf-button-soft-disabled-border-color: var(--pf-tertiary-color-450);
}

.btn {
  display: inline-flex;
  font-family: var(--pf-font-family-base);
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-width: var(--pf-border-sm);
  border-style: solid;
  border-color: transparent;
  border-radius: var(--pf-button-border-radius);
  font-weight: var(--pf-button-font-weight);
  cursor: pointer;
}
.btn:disabled {
  cursor: not-allowed;
}

.btn--xs {
  line-height: var(--pf-button-line-height-xs);
  padding: var(--pf-padding-0-5) var(--pf-padding-3);
  font-size: var(--pf-button-font-size-xs);
}

.btn--sm {
  line-height: var(--pf-button-line-height-sm);
  padding: var(--pf-padding-1-5) var(--pf-padding-3);
  font-size: var(--pf-button-font-size-sm);
}

.btn--md {
  line-height: var(--pf-button-line-height-md);
  padding: var(--pf-padding-2-5) var(--pf-padding-3-5);
  font-size: var(--pf-button-font-size-md);
}

.btn--lg {
  line-height: var(--pf-button-line-height-lg);
  padding: var(--pf-padding-2-5) var(--pf-padding-4);
  font-size: var(--pf-button-font-size-lg);
}

.btn--icon-only {
  padding-left: var(--pf-padding-2);
  padding-right: var(--pf-padding-2);
}

.btn--outline,
.btn--link,
.btn--soft {
  background-color: transparent;
}
.btn--outline:hover,
.btn--link:hover,
.btn--soft:hover {
  background-color: transparent;
}
.btn--outline:disabled,
.btn--link:disabled,
.btn--soft:disabled {
  background-color: transparent;
}

.btn--solid {
  background-color: var(--pf-button-solid-background-color);
  color: var(--pf-button-solid-color);
}
.btn--solid:hover {
  background-color: var(--pf-button-solid-hover-background-color);
}
.btn--solid:focus {
  background-color: var(--pf-button-solid-focus-background-color);
  box-shadow: var(--pf-button-solid-focus-box-shadow);
}
.btn--solid:disabled {
  background-color: var(--pf-button-solid-disabled-background-color);
  color: var(--pf-button-solid-disabled-color);
}

.btn--action {
  padding: var(--pf-padding-2);
  background-color: transparent;
  border-color: var(--pf-button-action-border-color);
  color: var(--pf-button-action-color);
}
.btn--action:hover {
  background-color: var(--pf-button-action-hover-background-color);
  border-color: var(--pf-button-action-hover-border-color);
  color: var(--pf-button-action-hover-color);
}
.btn--action:focus {
  background-color: transparent;
  border-color: var(--pf-button-action-border-color);
  box-shadow: var(--pf-button-action-focus-box-shadow);
  color: var(--pf-button-action-color);
}
.btn--action:disabled {
  background-color: transparent;
  border-color: var(--pf-button-action-disabled-border-color);
  color: var(--pf-button-action-disabled-color);
}

.btn--link {
  color: var(--pf-button-link-color);
}
.btn--link:hover:not(:disabled) {
  text-decoration: underline;
  color: var(--pf-button-link-hover-color);
}
.btn--link:focus {
  text-decoration: underline;
  color: var(--pf-button-link-hover-color);
  background-color: transparent;
}
.btn--link:disabled {
  color: var(--pf-button-link-disabled-color);
}

.btn--outline {
  border: var(--pf-border-sm) solid var(--pf-button-outline-border-color);
  color: var(--pf-button-outline-color);
}
.btn--outline:hover:not(:disabled) {
  color: var(--pf-button-outline-hover-color);
  border-color: var(--pf-button-outline-hover-color);
}
.btn--outline:focus {
  background-color: transparent;
  box-shadow: var(--pf-button-outline-focus-box-shadow);
}
.btn--outline:disabled {
  color: var(--pf-button-outline-disabled-color);
  border-color: var(--pf-button-outline-disabled-color);
}

.btn--destructive {
  background-color: var(--pf-button-destructive-background-color);
  color: var(--pf-button-destructive-color);
}
.btn--destructive:hover {
  background-color: var(--pf-button-destructive-hover-background-color);
}
.btn--destructive:focus {
  background-color: var(--pf-button-destructive-focus-background-color);
  color: var(--pf-button-destructive-focus-color);
  box-shadow: var(--pf-button-destructive-focus-box-shadow);
}
.btn--destructive:disabled {
  background-color: var(--pf-button-destructive-disabled-background-color);
  color: var(--pf-button-destructive-disabled-color);
}

.btn--soft {
  color: var(--pf-button-soft-color);
  border: var(--pf-border-sm) solid var(--pf-button-soft-border-color);
}
.btn--soft:hover {
  color: var(--pf-button-soft-hover-color);
  border-color: var(--pf-button-soft-hover-border-color);
}
.btn--soft:focus {
  background-color: transparent;
  color: var(--pf-button-soft-focus-color);
  border-color: var(--pf-button-soft-focus-border-color);
  box-shadow: var(--pf-button-soft-focus-box-shadow);
}
.btn--soft:disabled {
  color: var(--pf-button-soft-disabled-color);
  border-color: var(--pf-button-soft-disabled-border-color);
}

.btn--pill {
  border-radius: var(--pf-button-pill-border-radius);
}

.icon {
  display: inline-block;
}
.icon--xs {
  width: var(--pf-icon-size-xs);
  height: var(--pf-icon-size-xs);
  font-size: var(--pf-icon-size-xs);
}
.icon--sm {
  width: var(--pf-icon-size-sm);
  height: var(--pf-icon-size-sm);
  font-size: var(--pf-icon-size-sm);
}
.icon--md {
  width: var(--pf-icon-size-md);
  height: var(--pf-icon-size-md);
  font-size: var(--pf-icon-size-md);
}
.icon--lg {
  width: var(--pf-icon-size-lg);
  height: var(--pf-icon-size-lg);
  font-size: var(--pf-icon-size-lg);
}
.icon--xl {
  width: var(--pf-icon-size-xl);
  height: var(--pf-icon-size-xl);
  font-size: var(--pf-icon-size-xl);
}

.sb__sample-cell {
  background: var(--pf-secondary-color-550);
  color: var(--pf-white-color);
  padding: 1rem;
  margin: 2px 0;
}
.sb__sample-cell-secondary {
  background: var(--pf-secondary-color-400);
  color: var(--pf-white-color);
  padding: 1rem;
  margin: 2px 0;
}
.sb__sample-cell-green {
  background: var(--pf-green-color-400);
  color: var(--pf-secondary-color-900);
  padding: 1rem;
  margin: 2px 0;
}
.sb__sample-cell-orange {
  background: var(--pf-orange-color-400);
  color: var(--pf-secondary-color-900);
  padding: 1rem;
  margin: 2px 0;
}
.sb__sample-cell-red {
  background: var(--pf-red-color-400);
  color: var(--pf-white-color);
  padding: 1rem;
  margin: 2px 0;
}

.sb__sample-row {
  min-height: 110px;
  margin: 1em 0;
}

:root [data-theme=light] {
  --pf-table-background-color: var(--pf-white-color);
  --pf-table-font-color: var(--pf-gray-color);
  --pf-table-border-color: var(--pf-gray-color-200);
  --pf-table-stripe-color: var(--pf-gray-color-100);
  --pf-table-hover-color: var(--pf-gray-color-200);
  --pf-table-disabled-color: var(--pf-gray-color-300);
  --pf-table-checked-color: var(--pf-secondary-color-100);
  --pf-table-highlighted-color: var(--pf-gray-color-200);
  --pf-table-highlighted-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.2);
  --pf-table-font-size: var(--pf-font-size-body2);
}

:root [data-theme=dark],
:root {
  --pf-table-background-color: var(--pf-primary-color-800);
  --pf-table-font-color: var(--pf-white-color);
  --pf-table-border-color: var(--pf-tertiary-color-800);
  --pf-table-stripe-color: var(--pf-primary-color-700);
  --pf-table-hover-color: var(--pf-primary-color-600);
  --pf-table-disabled-color: var(--pf-gray-color-900);
  --pf-table-disabled-button-color: var(--pf-primary-color-100);
  --pf-table-checked-color: var(--pf-secondary-color-100);
  --pf-table-highlighted-color: var(--pf-primary-color);
  --pf-table-highlighted-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4), 0 8px 16px rgba(0, 0, 0, 0.3);
  --pf-table-font-size: var(--pf-font-size-body2);
}

.table-loading {
  background-color: var(--pf-table-background-color);
  color: var(--pf-table-font-color);
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table {
  border-radius: var(--pf-rounded);
  border: var(--pf-border-sm) solid var(--pf-table-border-color);
  /* Striped: alternating background */
  /* Checked: Precedence over striped */
  /* Highlighted: Precedence over checked */
}
.table--full-height {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
}
.table-body {
  background-color: var(--pf-table-background-color);
  color: var(--pf-table-font-color);
  border-radius: var(--pf-rounded) !important;
  border: var(--pf-border-sm) solid var(--pf-table-border-color);
}
.table > *:nth-child(3) {
  margin-top: auto;
  background-color: transparent;
  border: none;
}
.table > *:nth-child(3) .rdt_Pagination {
  background-color: transparent;
  border: none;
}
.table .rdt_Table,
.table .rdt_TableRow,
.table .rdt_TableCol,
.table .rdt_TableCol_Sortable,
.table .rdt_TableCell,
.table .rdt_TableHeader,
.table .rdt_TableFooter,
.table .rdt_TableHead,
.table .rdt_TableHeadRow,
.table .rdt_TableBody,
.table .rdt_ExpanderRow,
.table .rdt_Pagination {
  background-color: var(--pf-table-background-color);
  color: var(--pf-table-font-color);
}
.table button svg {
  fill: var(--pf-table-font-color);
}
.table button:disabled svg {
  fill: var(--pf-table-disabled-button-color);
}
.table .rdt_TableHeader {
  border-radius: var(--pf-rounded) 0;
  border-bottom: var(--pf-border-sm) solid var(--pf-table-border-color);
}
.table .rdt_TableHeadRow > :first-child,
.table .rdt_TableRow > :first-child {
  padding-left: var(--pf-padding-4);
  min-width: 60px;
  justify-content: left;
}
.table .rdt_TableRow {
  border-top: var(--pf-border-sm) solid var(--pf-table-border-color);
  border-bottom: var(--pf-border-sm) solid var(--pf-table-border-color);
}
.table .rdt_TableRow:hover .rdt_TableCell {
  background-color: var(--pf-table-hover-color) !important;
}
.table .rdt_TableRow:first-child .rdt_TableCell {
  border-top: var(--pf-border-sm) solid var(--pf-table-border-color);
}
.table .rdt_TableCell,
.table .rdt_TableCol {
  font-size: var(--pf-table-font-size);
}
.table .rdt_TableCell:not(:first-child),
.table .rdt_TableCol:not(:first-child) {
  border-left: var(--pf-border-sm) solid var(--pf-table-border-color);
}
.table .table--striped .rdt_TableRow:nth-child(odd) .rdt_TableCell {
  background-color: var(--pf-table-stripe-color);
}
.table .checked .rdt_TableCell {
  background-color: var(--pf-table-checked-color) !important;
}
.table .highlighted {
  box-shadow: var(--pf-table-highlighted-box-shadow);
  transform: translateY(-2px);
  border-bottom: var(--pf-border-md) solid var(--pf-table-highlighted-color);
}
.table .highlighted .rdt_TableCell {
  border-top: var(--pf-border-md) solid var(--pf-table-highlighted-color);
  background-color: var(--pf-table-highlighted-color) !important;
}

.input {
  padding: 10px;
}
.input--has-icon {
  padding-left: var(--pf-padding-7);
}

.form-control {
  margin-bottom: var(--pf-margin-3);
}
.form-control .help-text {
  margin-top: var(--pf-margin-2);
  margin-bottom: var(--pf-margin-2);
  color: var(--pf-form-input-help-color);
  font-size: var(--pf-font-size-subtitle2);
}
.form-control .input-wrapper {
  position: relative;
}
.form-control .input-wrapper .embedded-icon {
  position: absolute;
  top: 10px;
  left: var(--pf-margin-2);
  color: var(--pf-form-input-color);
}
.form-control .input-wrapper .clearable-icon {
  position: absolute;
  top: var(--pf-margin-3);
  right: var(--pf-margin-2);
  color: var(--pf-form-input-color);
  cursor: pointer;
}
.form-control .form-label {
  margin-bottom: var(--pf-margin-2);
}

:root {
  --pf-number-input-padding: 10px;
}

.number-input {
  padding-right: var(--pf-number-input-padding);
  padding-left: var(--pf-number-input-padding);
}
.number-input--is-clearable {
  padding-right: var(--pf-padding-7);
}
.number-input--has-icon {
  padding-left: var(--pf-padding-7);
}

.form-control .help-text {
  margin-top: var(--pf-margin-2);
  margin-bottom: var(--pf-margin-2);
  color: var(--pf-form-input-help-color);
  font-size: var(--pf-font-size-subtitle2);
}
.form-control .number-input-wrapper {
  position: relative;
}
.form-control .number-input-wrapper .embedded-icon {
  position: absolute;
  top: 10px;
  left: var(--pf-margin-2);
  color: var(--pf-form-input-color);
}
.form-control .number-input-wrapper .clearable-icon {
  position: absolute;
  top: var(--pf-margin-3);
  right: var(--pf-margin-2);
  color: var(--pf-form-input-color);
  cursor: pointer;
}
.form-control .form-label {
  margin-bottom: var(--pf-margin-2);
}

:root [data-theme=light] {
  --pf-radio-check-color: var(--pf-primary-color);
  --pf-radio-disabled-color: var(--pf-gray-color-400);
}

:root [data-theme=dark],
:root {
  --pf-radio-check-color: var(--pf-white-color);
  --pf-radio-disabled-color: var(--pf-gray-color-300);
}

.form-control .radio-wrapper {
  display: flex;
  margin-bottom: var(--pf-margin-2);
  align-items: center;
}
.form-control .radio-input {
  margin: 0;
  margin-right: var(--pf-margin-2);
  cursor: pointer;
}
.form-control .radio-input-label {
  cursor: pointer;
}
.form-control [type=radio]:checked,
.form-control [type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.form-control [type=radio]:checked + label,
.form-control [type=radio]:not(:checked) + label {
  position: relative;
  padding-left: var(--pf-padding-7);
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}
.form-control [type=radio]:checked + label:before,
.form-control [type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid var(--pf-form-input-border-color);
  border-radius: 100%;
  background: var(--pf-form-input-background-color);
}
.form-control [type=radio]:checked + label:after,
.form-control [type=radio]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: var(--pf-radio-check-color);
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.form-control [type=radio]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.form-control [type=radio]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.form-control [type=radio]:disabled,
.form-control [type=radio]:disabled + label {
  cursor: not-allowed;
}
.form-control [type=radio]:disabled + label {
  color: var(--pf-radio-disabled-color);
  opacity: 0.5;
}
.form-control [type=radio]:disabled + label:before {
  border-color: var(--pf-radio-disabled-color);
}
.form-control [type=radio]:disabled + label:after {
  background: var(--pf-radio-disabled-color);
}

:root [data-theme=light] {
  --pf-checkbox-check-color: var(--pf-primary-color);
  --pf-checkbox-disabled-color: var(--pf-gray-color-400);
}

:root [data-theme=dark],
:root {
  --pf-checkbox-check-color: var(--pf-white-color);
  --pf-checkbox-disabled-color: var(--pf-gray-color-300);
}

.form-control .checkbox-wrapper {
  display: flex;
  margin-bottom: var(--pf-margin-2);
  align-items: center;
}
.form-control .checkbox-input {
  margin: 0;
  margin-right: var(--pf-margin-2);
  cursor: pointer;
}
.form-control .checkbox-input-label {
  cursor: pointer;
}
.form-control .checkbox-input:checked,
.form-control .checkbox-input:not(:checked) {
  position: absolute;
  left: -9999px;
}
.form-control .checkbox-input:checked + label,
.form-control .checkbox-input:not(:checked) + label {
  position: relative;
  padding-left: var(--pf-padding-7);
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}
.form-control .checkbox-input:checked + label:before,
.form-control .checkbox-input:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid var(--pf-form-input-border-color);
  border-radius: var(--pf-rounded);
  background: var(--pf-form-input-background-color);
}
.form-control .checkbox-input:checked + label:after,
.form-control .checkbox-input:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: var(--pf-checkbox-check-color);
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: var(--pf-rounded);
  transition: all 0.2s ease;
}
.form-control .checkbox-input:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.form-control .checkbox-input:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.form-control .checkbox-input:disabled,
.form-control .checkbox-input:disabled + label {
  cursor: not-allowed;
}
.form-control .checkbox-input:disabled + label {
  color: var(--pf-checkbox-disabled-color);
  opacity: 0.5;
}
.form-control .checkbox-input:disabled + label:before {
  border-color: var(--pf-checkbox-disabled-color);
}
.form-control .checkbox-input:disabled + label:after {
  background: var(--pf-form-input-disabled-background-color);
}

.textarea {
  padding: 10px;
}
.textarea.error {
  border-color: var(--pf-error-color);
}
.textarea.success {
  border-color: var(--pf-success-color);
}
.textarea.warning {
  border-color: var(--pf-warning-color);
}
.textarea.info {
  border-color: var(--pf-info-color);
}
.textarea--has-icon {
  padding-left: var(--pf-padding-7);
}

.form-control .form-errors {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: var(--pf-margin-2);
  margin-bottom: var(--pf-margin-2);
  color: var(--pf-error-color);
}
.form-control .help-text {
  margin-top: var(--pf-margin-2);
  margin-bottom: var(--pf-margin-2);
  color: var(--pf-form-input-help-color);
  font-size: var(--pf-font-size-subtitle2);
}
.form-control .form-label {
  margin-bottom: var(--pf-margin-2);
}

.password-input {
  padding: 10px;
}
.password-input--has-icon {
  padding-left: var(--pf-padding-7);
}

.form-control .error-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: var(--pf-margin-2);
  margin-bottom: var(--pf-margin-2);
  color: var(--pf-error-color);
}
.form-control .help-text {
  margin-top: var(--pf-margin-2);
  margin-bottom: var(--pf-margin-2);
  color: var(--pf-form-input-help-color);
  font-size: var(--pf-font-size-subtitle2);
}
.form-control .password-input-wrapper {
  position: relative;
}
.form-control .password-input-wrapper .embedded-icon {
  position: absolute;
  top: 10px;
  left: var(--pf-margin-2);
  color: var(--pf-form-input-color);
}
.form-control .password-input-wrapper .toggle-show-password-icon {
  position: absolute;
  top: var(--pf-margin-3);
  right: var(--pf-margin-2);
  color: var(--pf-form-input-color);
  cursor: pointer;
}
.form-control .form-label {
  margin-bottom: var(--pf-margin-2);
}

form {
  width: 100%;
}

:root {
  --pf-form-input-rounded: var(--pf-rounded);
}

:root [data-theme=light] {
  --pf-form-input-border-color: var(--pf-border-color);
  --pf-form-input-background-color: var(--pf-white-color);
  --pf-form-input-color: var(--pf-gray-color);
  --pf-form-input-placeholder-color: var(--pf-gray-color-300);
  --pf-form-input-help-color: var(--pf-gray-color-400);
  --pf-form-input-hover-background-color: var(--pf-gray-color-100);
  --pf-form-input-focus-background-color: var(--pf-gray-color-100);
  --pf-form-input-focus-border-color: var(--pf-secondary-color-800);
  --pf-form-input-focus-outline-color: var(--pf-primary-color);
  --pf-form-input-disabled-background-color: var(--pf-gray-color-100);
  --pf-form-input-disabled-border-color: var(--pf-gray-color-100);
  --pf-form-input-disabled-color: var(--pf-gray-color-400);
}

:root [data-theme=dark],
:root {
  --pf-form-input-border-color: var(--pf-border-color);
  --pf-form-input-background-color: var(--pf-primary-color-700);
  --pf-form-input-color: var(--pf-gray-color-100);
  --pf-form-input-placeholder-color: var(--pf-primary-color-50);
  --pf-form-input-help-color: var(--pf-gray-color-200);
  --pf-form-input-hover-background-color: var(--pf-primary-color-800);
  --pf-form-input-focus-background-color: var(--pf-primary-color-800);
  --pf-form-input-focus-border-color: var(--pf-secondary-color-700);
  --pf-form-input-focus-outline-color: var(--pf-primary-color);
  --pf-form-input-disabled-background-color: var(--pf-tertiary-color-450);
  --pf-form-input-disabled-border-color: var(--pf-tertiary-color-450);
  --pf-form-input-disabled-color: var(--pf-tertiary-color-950);
}

.input-wrapper input,
.number-input-wrapper input,
.password-input-wrapper input,
.textarea-wrapper input {
  height: var(--pf-size-9);
}
.input-wrapper input,
.input-wrapper textarea,
.number-input-wrapper input,
.number-input-wrapper textarea,
.password-input-wrapper input,
.password-input-wrapper textarea,
.textarea-wrapper input,
.textarea-wrapper textarea {
  background-color: var(--pf-form-input-background-color);
  border: 1px solid var(--pf-form-input-border-color);
  border-radius: var(--pf-form-input-rounded);
  color: var(--pf-form-input-color);
  width: 100%;
  box-sizing: border-box;
}
.input-wrapper input::-moz-placeholder, .input-wrapper textarea::-moz-placeholder, .number-input-wrapper input::-moz-placeholder, .number-input-wrapper textarea::-moz-placeholder, .password-input-wrapper input::-moz-placeholder, .password-input-wrapper textarea::-moz-placeholder, .textarea-wrapper input::-moz-placeholder, .textarea-wrapper textarea::-moz-placeholder {
  color: var(--pf-form-input-placeholder-color);
}
.input-wrapper input::placeholder,
.input-wrapper textarea::placeholder,
.number-input-wrapper input::placeholder,
.number-input-wrapper textarea::placeholder,
.password-input-wrapper input::placeholder,
.password-input-wrapper textarea::placeholder,
.textarea-wrapper input::placeholder,
.textarea-wrapper textarea::placeholder {
  color: var(--pf-form-input-placeholder-color);
}
.input-wrapper input:hover,
.input-wrapper textarea:hover,
.number-input-wrapper input:hover,
.number-input-wrapper textarea:hover,
.password-input-wrapper input:hover,
.password-input-wrapper textarea:hover,
.textarea-wrapper input:hover,
.textarea-wrapper textarea:hover {
  background-color: var(--pf-form-input-hover-background-color);
}
.input-wrapper input:focus,
.input-wrapper textarea:focus,
.number-input-wrapper input:focus,
.number-input-wrapper textarea:focus,
.password-input-wrapper input:focus,
.password-input-wrapper textarea:focus,
.textarea-wrapper input:focus,
.textarea-wrapper textarea:focus {
  border-color: var(--pf-form-input-focus-border-color);
  background-color: var(--pf-form-input-focus-background-color);
}
.input-wrapper input:focus-visible,
.input-wrapper textarea:focus-visible,
.number-input-wrapper input:focus-visible,
.number-input-wrapper textarea:focus-visible,
.password-input-wrapper input:focus-visible,
.password-input-wrapper textarea:focus-visible,
.textarea-wrapper input:focus-visible,
.textarea-wrapper textarea:focus-visible {
  outline: 1px solid var(--pf-form-input-focus-outline-color);
}
.input-wrapper input:disabled,
.input-wrapper textarea:disabled,
.number-input-wrapper input:disabled,
.number-input-wrapper textarea:disabled,
.password-input-wrapper input:disabled,
.password-input-wrapper textarea:disabled,
.textarea-wrapper input:disabled,
.textarea-wrapper textarea:disabled {
  background-color: var(--pf-form-input-disabled-background-color);
  border-color: var(--pf-form-input-disabled-border-color);
  color: var(--pf-form-input-disabled-color);
}
.input-wrapper input.error,
.input-wrapper textarea.error,
.number-input-wrapper input.error,
.number-input-wrapper textarea.error,
.password-input-wrapper input.error,
.password-input-wrapper textarea.error,
.textarea-wrapper input.error,
.textarea-wrapper textarea.error {
  border-color: var(--pf-error-color);
}
.input-wrapper input.success,
.input-wrapper textarea.success,
.number-input-wrapper input.success,
.number-input-wrapper textarea.success,
.password-input-wrapper input.success,
.password-input-wrapper textarea.success,
.textarea-wrapper input.success,
.textarea-wrapper textarea.success {
  border-color: var(--pf-success-color);
}
.input-wrapper input.warning,
.input-wrapper textarea.warning,
.number-input-wrapper input.warning,
.number-input-wrapper textarea.warning,
.password-input-wrapper input.warning,
.password-input-wrapper textarea.warning,
.textarea-wrapper input.warning,
.textarea-wrapper textarea.warning {
  border-color: var(--pf-warning-color);
}
.input-wrapper input.info,
.input-wrapper textarea.info,
.number-input-wrapper input.info,
.number-input-wrapper textarea.info,
.password-input-wrapper input.info,
.password-input-wrapper textarea.info,
.textarea-wrapper input.info,
.textarea-wrapper textarea.info {
  border-color: var(--pf-info-color);
}

:root [data-theme=light] {
  --pf-select-indicator-color: var(--pf-gray-color);
  --pf-select-option-selected-color: var(--pf-primary-color-100);
  --pf-select-option-color: var(--pf-gray-color);
  --pf-select-option-hover-color: var(--pf-primary-color-100);
}

:root [data-theme=dark],
:root {
  --pf-select-indicator-color: var(--pf-gray-color-100);
  --pf-select-option-selected-color: var(--pf-tertiary-color-700);
  --pf-select-option-color: var(--pf-gray-color-100);
  --pf-select-option-hover-color: var(--pf-primary-color);
}

.select-wrapper .select__control {
  background-color: var(--pf-form-input-background-color);
  border: 1px solid var(--pf-form-input-border-color);
  color: var(--pf-form-input-color);
  box-shadow: none;
}
.select-wrapper .select__control:hover:not(:focus) {
  background-color: var(--pf-form-input-hover-background-color);
  border-color: var(--pf-form-input-border-color);
}
.select-wrapper .select__control:focus, .select-wrapper .select__control--is-focused {
  border: 1px solid var(--pf-form-input-focus-border-color);
}
.select-wrapper .select__control--is-disabled {
  background-color: var(--pf-form-input-disabled-background-color);
  color: var(--pf-form-input-disabled-color);
}
.select-wrapper .select__placeholder {
  color: var(--pf-form-input-placeholder-color);
}
.select-wrapper .select__value-container {
  cursor: text;
}
.select-wrapper .select__input-container {
  color: var(--pf-form-input-color);
}
.select-wrapper .select__multi-value {
  background-color: var(--pf-select-option-selected-color);
  border-radius: var(--pf-rounded);
}
.select-wrapper .select__multi-value__label {
  padding-top: 5px;
  color: var(--pf-select-option-color);
}
.select-wrapper .select__multi-value__remove {
  color: var(--pf-select-option-color);
  cursor: pointer;
}
.select-wrapper .select__multi-value__remove:hover {
  background-color: var(--pf-select-option-hover-color);
  color: var(--pf-form-input-hover-color);
}
.select-wrapper .select__menu {
  border: 1px solid var(--pf-form-input-border-color);
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.4);
  margin: 4px 0px;
  background-color: var(--pf-form-input-background-color);
}
.select-wrapper .select__menu-notice {
  color: var(--pf-form-input-color);
}
.select-wrapper .select__indicator-separator {
  background-color: var(--pf-select-indicator-color);
}
.select-wrapper .select__indicator {
  cursor: pointer;
  color: var(--pf-select-indicator-color);
}
.select-wrapper .select__indicator:hover, .select-wrapper .select__indicator:focus {
  color: var(--pf-form-input-color);
}
.select-wrapper .select__items {
  display: flex;
  align-items: center;
}
.select-wrapper .select__item-detail {
  margin-left: auto;
}
.select-wrapper .select__option {
  cursor: pointer;
  overflow-wrap: break-word;
  color: var(--pf-select-option-color);
}
.select-wrapper .select__option--is-focused, .select-wrapper .select__option:active {
  background: var(--pf-select-option-hover-color);
}
.select-wrapper .select__option--is-selected {
  background: var(--pf-select-option-selected-color);
}
.select-wrapper .select__single-value {
  color: var(--pf-select-option-color);
}

:root [data-theme=light] {
  --pf-toggle-background-color: var(--pf-form-input-background-color);
  --pf-toggle-circle-color: var(--pf-primary-color);
  --pf-toggle-border-color: var(--pf-form-input-border-color);
  --pf-toggle-disabled-background-color: var(--pf-form-input-disabled-background-color);
  --pf-toggle-disabled-circle-color: var(--pf-form-input-disabled-color);
  --pf-toggle-checked-background-color: var(--pf-primary-color-300);
}

:root [data-theme=dark],
:root {
  --pf-toggle-background-color: var(--pf-form-input-background-color);
  --pf-toggle-circle-color: var(--pf-white-color);
  --pf-toggle-border-color: var(--pf-primary-color);
  --pf-toggle-disabled-background-color: var(--pf-form-input-disabled-background-color);
  --pf-toggle-disabled-circle-color: var(--pf-form-input-disabled-color);
  --pf-toggle-checked-background-color: var(--pf-secondary-color-400);
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: var(--pf-toggle-background-color);
  border-radius: 20px;
  transition: all 0.3s;
  cursor: pointer;
  border: 1px solid var(--pf-toggle-border-color);
}

.switch::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--pf-toggle-circle-color);
  top: 1px;
  left: 1px;
  transition: all 0.3s;
}

.toggle-input:checked + label.switch::after {
  left: 20px;
}

.toggle-input:checked + label.switch {
  background-color: var(--pf-toggle-checked-background-color);
}

.toggle-input:checked,
.toggle-input:not(:checked) {
  position: absolute;
  left: -9999px;
}

.toggle-input:disabled + label.switch {
  background-color: var(--pf-toggle-disabled-background-color);
  cursor: not-allowed;
}

.toggle-input:disabled + label.switch::after {
  background-color: var(--pf-toggle-disabled-circle-color);
}

.toggle-wrapper {
  display: flex;
  align-items: center;
}

.toggle-label {
  cursor: pointer;
  margin-left: var(--pf-margin-2);
}

:root,
:root [data-theme=light],
:root [data-theme=dark] {
  --pf-skeleton-color-one: var(--pf-gray-color-200);
  --pf-skeleton-color-two: var(--pf-gray-color-100);
}

:root [data-theme=dark] {
  --pf-skeleton-color-one: var(--pf-primary-color-500);
  --pf-skeleton-color-two: var(--pf-primary-color-400);
}

.skeleton {
  border-radius: var(--pf-rounded);
  background-color: var(--pf-gray-color-100);
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30px;
}
.skeleton.skeleton--full-height {
  height: 100%;
}
.skeleton.skeleton--circle {
  border-radius: var(--pf-rounded-circle);
}
@keyframes skeleton-loading {
  0% {
    background-color: var(--pf-skeleton-color-one);
  }
  100% {
    background-color: var(--pf-skeleton-color-two);
  }
}

:root {
  --pf-card-rounded: var(--pf-rounded);
}

:root [data-theme=light] {
  --pf-card-background-color: var(--pf-white-color);
  --pf-card-border-color: var(--pf-border-color);
}

:root [data-theme=dark],
:root {
  --pf-card-background-color: var(--pf-primary-color-600);
  --pf-card-border-color: var(--pf-border-color);
}

.card {
  border-radius: var(--pf-rounded);
  padding: var(--pf-padding-3);
  background: var(--pf-card-background-color);
  border: var(--pf-border-sm) solid var(--pf-card-border-color);
  box-sizing: border-box;
}

.card__header {
  margin-bottom: var(--pf-margin-2);
}
.card__header p {
  font-size: var(--pf-font-size-subtitle2);
}

.card__content hr {
  border: var(--pf-border-thin) solid var(--pf-card-border-color);
  border-bottom: none;
  margin-bottom: var(--pf-margin-3);
  margin-top: var(--pf-margin-3);
}

.card--box-shadow {
  box-shadow: var(--pf-dropshadow);
}

:root {
  --pf-menu-rounded: var(--pf-rounded);
}

:root [data-theme=light] {
  --pf-menu-item-hover-color: var(--pf-primary-color-100);
  --pf-menu-item-background-color: var(--pf-white-color);
  --pf-menu-item-color: var(--pf-gray-color);
  --pf-menu-item-focus-color: var(--pf-primary-color-300);
}

:root [data-theme=dark],
:root {
  --pf-menu-item-hover-color: var(--pf-primary-color-500);
  --pf-menu-item-background-color: var(--pf-primary-color-600);
  --pf-menu-item-color: var(--pf-gray-color-50);
  --pf-menu-item-focus-color: var(--pf-primary-color-500);
}

.menu {
  border-radius: var(--pf-menu-rounded);
}
.menu .menu-item {
  width: 100%;
  background: var(--pf-menu-item-background-color);
  color: var(--pf-menu-item-color);
  display: block;
  width: 100%;
  text-align: left;
  border: none;
  box-shadow: none;
}
.menu .menu-item:hover {
  background: var(--pf-menu-item-hover-color);
}
.menu .menu-item:focus {
  background-color: var(--pf-menu-item-focus-color);
}

:root {
  --pf-floatui-border-radius: var(--pf-rounded);
  --pf-floatui-box-shadow: var(--pf-dropshadow);
  --pf-floatui-border: none;
  --pf-floatui-border-color: var(--pf-border-color);
}

:root [data-theme=light] {
  --pf-floatui-background-color: var(--pf-white-color);
}

:root [data-theme=dark],
:root {
  --pf-floatui-background-color: var(--pf-primary-color-600);
}

.floatui-container {
  z-index: 999;
}

.floatui-content {
  border-radius: var(--pf-floatui-border-radius);
  box-shadow: var(--pf-floatui-box-shadow);
  background: var(--pf-floatui-background-color);
}

:root [data-theme=light] {
  --pf-date-picker-background-color: var(--pf-white-color);
  --pf-date-picker-selected-date-background-color: var(--pf-primary-color);
  --pf-date-picker-selected-date-font-color: var(--pf-white-color);
  --pf-date-picker-today-background-color: var(--pf-primary-color-200);
}
:root [data-theme=light] .rdp-root {
  --rdp-accent-color: var(--pf-link-color);
  --rdp-font-family: var(--pf-font-family-base);
  --rdp-range_middle-background-color: var(--pf-primary-color-300);
}

:root [data-theme=dark],
:root {
  --pf-date-picker-background-color: var(--pf-primary-color-600);
  --pf-date-picker-selected-date-background-color: var(--pf-secondary-color-400);
  --pf-date-picker-selected-date-font-color: var(--pf-white-color);
  --pf-date-picker-today-background-color: var(--pf-primary-color);
}
:root [data-theme=dark] .rdp-root,
:root .rdp-root {
  --rdp-accent-color: var(--pf-link-color);
  --rdp-accent-background-color: var(--pf-primary-color-200);
  --rdp-font-family: var(--pf-font-family-base);
  --rdp-range_middle-background-color: var(--pf-primary-color-700);
}

.rdp-chevron {
  margin-left: var(--pf-margin-1);
}

.rdp-months {
  background-color: var(--pf-date-picker-background-color);
  padding: var(--pf-padding-3);
  border-radius: var(--pf-rounded);
  border: solid var(--pf-border-thin) var(--pf-border-color);
}
.rdp-months .rdp-nav {
  margin-top: var(--pf-margin-3);
  margin-right: var(--pf-margin-3);
}
.rdp-months .rdp-month_caption {
  padding-left: var(--pf-margin-3);
}

.rdp-week {
  margin-bottom: var(--pf-margin-4);
}

.rdp-day .rdp-day_button {
  border: none;
}
.rdp-day.rdp-today .rdp-day_button {
  background-color: var(--pf-date-picker-today-background-color);
  color: var(--pf-date-picker-selected-date-font-color);
}
.rdp-day.rdp-selected .rdp-day_button {
  background-color: var(--pf-date-picker-selected-date-background-color);
  color: var(--pf-date-picker-selected-date-font-color);
  border-color: var(--pf-border-color);
}
.rdp-day.rdp-range_middle .rdp-day_button {
  background-color: transparent;
}

:root [data-theme=light] {
  --pf-pill-primary-background-color: var(--pf-primary-color);
  --pf-pill-primary-font-color: var(--pf-white-color);
  --pf-pill-primary-border-color: var(--pf-primary-color);
  --pf-pill-secondary-background-color: var(--pf-secondary-color);
  --pf-pill-secondary-font-color: var(--pf-white-color);
  --pf-pill-secondary-border-color: var(--pf-secondary-color);
  --pf-pill-error-background-color: var(--pf-error-color);
  --pf-pill-error-font-color: var(--pf-white-color);
  --pf-pill-error-border-color: var(--pf-error-color);
  --pf-pill-warning-background-color: var(--pf-warning-color);
  --pf-pill-warning-font-color: var(--pf-white-color);
  --pf-pill-warning-border-color: var(--pf-warning-color);
  --pf-pill-info-background-color: var(--pf-info-color);
  --pf-pill-info-font-color: var(--pf-white-color);
  --pf-pill-info-border-color: var(--pf-info-color);
  --pf-pill-success-background-color: var(--pf-success-color);
  --pf-pill-success-font-color: var(--pf-white-color);
  --pf-pill-success-border-color: var(--pf-success-color);
  --pf-pill-neutral-background-color: var(--pf-gray-color);
  --pf-pill-neutral-font-color: var(--pf-white-color);
  --pf-pill-neutral-border-color: var(--pf-gray-color);
}

:root [data-theme=dark],
:root {
  --pf-pill-primary-background-color: var(--pf-primary-color-900);
  --pf-pill-primary-font-color: var(--pf-white-color);
  --pf-pill-primary-border-color: var(--pf-primary-color-100);
  --pf-pill-secondary-background-color: var(--pf-secondary-color-800);
  --pf-pill-secondary-font-color: var(--pf-white-color);
  --pf-pill-secondary-border-color: var(--pf-secondary-color-400);
  --pf-pill-error-background-color: var(--pf-red-color-900);
  --pf-pill-error-font-color: var(--pf-white-color);
  --pf-pill-error-border-color: var(--pf-red-color-600);
  --pf-pill-warning-background-color: var(--pf-orange-color-800);
  --pf-pill-warning-font-color: var(--pf-white-color);
  --pf-pill-warning-border-color: var(--pf-orange-color-600);
  --pf-pill-info-background-color: var(--pf-secondary-color-800);
  --pf-pill-info-font-color: var(--pf-white-color);
  --pf-pill-info-border-color: var(--pf-secondary-color-300);
  --pf-pill-success-background-color: var(--pf-green-color-900);
  --pf-pill-success-font-color: var(--pf-white-color);
  --pf-pill-success-border-color: var(--pf-green-color-600);
  --pf-pill-neutral-background-color: var(--pf-gray-color-900);
  --pf-pill-neutral-font-color: var(--pf-gray-color-100);
  --pf-pill-neutral-border-color: var(--pf-gray-color-700);
}

.pill {
  display: inline-block;
  border-radius: var(--pf-rounded-pill);
  padding: var(--pf-padding-1) var(--pf-padding-2);
  font-size: var(--pf-font-size-overline);
  font-weight: var(--pf-font-weight-medium);
  white-space: nowrap;
  line-height: 1;
  border: solid 1px;
}
.pill--sm {
  padding: var(--pf-padding-1) var(--pf-padding-2);
  font-size: var(--pf-font-size-overline);
}
.pill--md {
  padding: var(--pf-padding-2) var(--pf-padding-3);
  font-size: var(--pf-font-size-body);
}
.pill--lg {
  padding: var(--pf-padding-3) var(--pf-padding-4);
  font-size: var(--pf-font-size-h2);
}
.pill--error {
  background-color: var(--pf-pill-error-background-color);
  color: var(--pf-pill-error-font-color);
  border-color: var(--pf-pill-error-border-color);
}
.pill--warning {
  background-color: var(--pf-pill-warning-background-color);
  color: var(--pf-pill-warning-font-color);
  border-color: var(--pf-pill-warning-border-color);
}
.pill--info {
  background-color: var(--pf-pill-info-background-color);
  color: var(--pf-pill-info-font-color);
  border-color: var(--pf-pill-info-border-color);
}
.pill--success {
  background-color: var(--pf-pill-success-background-color);
  color: var(--pf-pill-success-font-color);
  border-color: var(--pf-pill-success-border-color);
}
.pill--neutral {
  background-color: var(--pf-pill-neutral-background-color);
  color: var(--pf-pill-neutral-font-color);
  border-color: var(--pf-pill-neutral-border-color);
}
.pill--primary {
  background-color: var(--pf-pill-primary-background-color);
  color: var(--pf-pill-primary-font-color);
  border-color: var(--pf-pill-primary-border-color);
}
.pill--secondary {
  background-color: var(--pf-pill-secondary-background-color);
  color: var(--pf-pill-secondary-font-color);
  border-color: var(--pf-pill-secondary-border-color);
}

:root [data-theme=light] {
  --sheets-background-color: var(--pf-gray-color-100);
}

:root [data-theme=dark],
:root {
  --sheets-background-color: var(--pf-primary-color-400);
}

:root {
  --pf-font-family-base: "Noto Sans", sans-serif;
  --pf-font-size-base: 1rem;
  --pf-font-size-h1: calc(1.5 * var(--pf-font-size-base));
  --pf-font-size-h2: calc(1.25 * var(--pf-font-size-base));
  --pf-font-size-h3: var(--pf-font-size-base);
  --pf-font-size-h4: calc(0.875 * var(--pf-font-size-base));
  --pf-font-size-h5: calc(0.75 * var(--pf-font-size-base));
  --pf-font-size-body: var(--pf-font-size-base);
  --pf-font-size-body2: calc(0.875 * var(--pf-font-size-base));
  --pf-font-size-caption: calc(0.875 * var(--pf-font-size-base));
  --pf-font-size-subtitle: var(--pf-font-size-base);
  --pf-font-size-subtitle2: calc(0.875 * var(--pf-font-size-base));
  --pf-font-size-button: var(--pf-font-size-base);
  --pf-font-size-overline: calc(0.75 * var(--pf-font-size-base));
  --pf-font-weight-thin: 100;
  --pf-font-weight-light: 300;
  --pf-font-weight-regular: 400;
  --pf-font-weight-medium: 500;
  --pf-font-weight-bold: 700;
  --pf-font-weight-heavy: 900;
  /* Labels */
  --pf-label-size: var(--pf-font-size-body);
  --pf-label-size-sm: var(--pf-font-size-overline);
  --pf-label-size-md: var(--pf-font-size-body);
  --pf-label-size-lg: var(--pf-font-size-h2);
  --pf-line-height-xs: calc(0.75 * var(--pf-font-size-base));
  --pf-line-height-sm: var(--pf-font-size-base);
  --pf-line-height-md: calc(1.25 * var(--pf-font-size-base));
  --pf-line-height-lg: calc(1.5 * var(--pf-font-size-base));
}

html {
  font-size: var(--pf-font-size-base);
  font-family: var(--pf-font-family-base);
}

h1,
.text-h1 {
  font-size: var(--pf-font-size-h1);
  font-family: var(--pf-font-family-base);
  margin: 0;
  padding: 0;
  font-weight: var(--pf-font-weight-bold);
}

h2,
.text-h2 {
  font-size: var(--pf-font-size-h2);
  font-family: var(--pf-font-family-base);
  margin: 0;
  padding: 0;
  font-weight: var(--pf-font-weight-bold);
}

h3,
.text-h3 {
  font-size: var(--pf-font-size-h3);
  font-family: var(--pf-font-family-base);
  margin: 0;
  padding: 0;
  font-weight: var(--pf-font-weight-bold);
}

h4,
.text-h4 {
  font-size: var(--pf-font-size-h4);
  font-family: var(--pf-font-family-base);
  margin: 0;
  padding: 0;
  font-weight: var(--pf-font-weight-bold);
}

h5,
.text-h5 {
  font-size: var(--pf-font-size-h5);
  font-family: var(--pf-font-family-base);
  margin: 0;
  padding: 0;
  font-weight: var(--pf-font-weight-bold);
}

p,
.text-p {
  font-size: var(--pf-font-size-body);
  font-family: var(--pf-font-family-base);
  margin: 0;
  padding: 0;
}

.subtitle {
  font-size: var(--pf-font-size-subtitle);
}

.subtitle-2 {
  font-size: var(--pf-font-size-subtitle2);
}

.text-body {
  font-size: var(--pf-font-size-body);
}

.text-body-2 {
  font-size: var(--pf-font-size-body2);
}

.text-caption {
  font-size: var(--pf-font-size-caption);
}

.text-overline {
  font-size: var(--pf-font-size-overline);
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-decoration--none {
  text-decoration: none;
}

.text-decoration--underline {
  text-decoration: underline;
}

.text-decoration--line-through {
  text-decoration: line-through;
}

.text-decoration--overline {
  text-decoration: overline;
}

.text-weight--thin {
  font-weight: var(--pf-font-weight-thin);
}

.text-weight--light {
  font-weight: var(--pf-font-weight-light);
}

.text-weight--regular {
  font-weight: var(--pf-font-weight-regular);
}

.text-weight--medium {
  font-weight: var(--pf-font-weight-medium);
}

.text-weight--bold {
  font-weight: var(--pf-font-weight-bold);
}

.text-weight--heavy {
  font-weight: var(--pf-font-weight-heavy);
}

.text-align--left {
  text-align: left;
}

.text-align--center {
  text-align: center;
}

.text-align--right {
  text-align: right;
}

.text-opacity--0 {
  opacity: 0;
}

.text-opacity--5 {
  opacity: 0.05;
}

.text-opacity--10 {
  opacity: 0.1;
}

.text-opacity--15 {
  opacity: 0.15;
}

.text-opacity--20 {
  opacity: 0.2;
}

.text-opacity--25 {
  opacity: 0.25;
}

.text-opacity--30 {
  opacity: 0.3;
}

.text-opacity--35 {
  opacity: 0.35;
}

.text-opacity--40 {
  opacity: 0.4;
}

.text-opacity--45 {
  opacity: 0.45;
}

.text-opacity--50 {
  opacity: 0.5;
}

.text-opacity--55 {
  opacity: 0.55;
}

.text-opacity--60 {
  opacity: 0.6;
}

.text-opacity--65 {
  opacity: 0.65;
}

.text-opacity--70 {
  opacity: 0.7;
}

.text-opacity--75 {
  opacity: 0.75;
}

.text-opacity--80 {
  opacity: 0.8;
}

.text-opacity--85 {
  opacity: 0.85;
}

.text-opacity--90 {
  opacity: 0.9;
}

.text-opacity--95 {
  opacity: 0.95;
}

.text-opacity--100 {
  opacity: 1;
}

.text-break--word {
  overflow-wrap: break-word;
}

.text-error {
  color: var(--pf-error-color);
}

.text-warning {
  color: var(--pf-warning-color);
}

.text-success {
  color: var(--pf-success-color);
}

.text-info {
  color: var(--pf-info-color);
}

.label-size--default {
  font-size: var(--pf-label-size);
}

.label-size--small {
  font-size: var(--pf-label-size-small);
}

.label-size--medium {
  font-size: var(--pf-label-size-medium);
}

.label-size--large {
  font-size: var(--pf-label-size-large);
}

.color-primary {
  color: var(--pf-primary-color);
}

.bg-primary {
  background-color: var(--pf-primary-color);
}

.color-primary-50 {
  color: var(--pf-primary-color-50);
}

.bg-primary-50 {
  background-color: var(--pf-primary-color-50);
}

.color-primary-100 {
  color: var(--pf-primary-color-100);
}

.bg-primary-100 {
  background-color: var(--pf-primary-color-100);
}

.color-primary-150 {
  color: var(--pf-primary-color-150);
}

.bg-primary-150 {
  background-color: var(--pf-primary-color-150);
}

.color-primary-200 {
  color: var(--pf-primary-color-200);
}

.bg-primary-200 {
  background-color: var(--pf-primary-color-200);
}

.color-primary-250 {
  color: var(--pf-primary-color-250);
}

.bg-primary-250 {
  background-color: var(--pf-primary-color-250);
}

.color-primary-300 {
  color: var(--pf-primary-color-300);
}

.bg-primary-300 {
  background-color: var(--pf-primary-color-300);
}

.color-primary-350 {
  color: var(--pf-primary-color-350);
}

.bg-primary-350 {
  background-color: var(--pf-primary-color-350);
}

.color-primary-400 {
  color: var(--pf-primary-color-400);
}

.bg-primary-400 {
  background-color: var(--pf-primary-color-400);
}

.color-primary-450 {
  color: var(--pf-primary-color-450);
}

.bg-primary-450 {
  background-color: var(--pf-primary-color-450);
}

.color-primary-500 {
  color: var(--pf-primary-color-500);
}

.bg-primary-500 {
  background-color: var(--pf-primary-color-500);
}

.color-primary-550 {
  color: var(--pf-primary-color-550);
}

.bg-primary-550 {
  background-color: var(--pf-primary-color-550);
}

.color-primary-600 {
  color: var(--pf-primary-color-600);
}

.bg-primary-600 {
  background-color: var(--pf-primary-color-600);
}

.color-primary-650 {
  color: var(--pf-primary-color-650);
}

.bg-primary-650 {
  background-color: var(--pf-primary-color-650);
}

.color-primary-700 {
  color: var(--pf-primary-color-700);
}

.bg-primary-700 {
  background-color: var(--pf-primary-color-700);
}

.color-primary-750 {
  color: var(--pf-primary-color-750);
}

.bg-primary-750 {
  background-color: var(--pf-primary-color-750);
}

.color-primary-800 {
  color: var(--pf-primary-color-800);
}

.bg-primary-800 {
  background-color: var(--pf-primary-color-800);
}

.color-primary-850 {
  color: var(--pf-primary-color-850);
}

.bg-primary-850 {
  background-color: var(--pf-primary-color-850);
}

.color-primary-900 {
  color: var(--pf-primary-color-900);
}

.bg-primary-900 {
  background-color: var(--pf-primary-color-900);
}

.color-primary-950 {
  color: var(--pf-primary-color-950);
}

.bg-primary-950 {
  background-color: var(--pf-primary-color-950);
}

.color-primary-1000 {
  color: var(--pf-primary-color-1000);
}

.bg-primary-1000 {
  background-color: var(--pf-primary-color-1000);
}

.color-secondary {
  color: var(--pf-secondary-color);
}

.bg-secondary {
  background-color: var(--pf-secondary-color);
}

.color-secondary-50 {
  color: var(--pf-secondary-color-50);
}

.bg-secondary-50 {
  background-color: var(--pf-secondary-color-50);
}

.color-secondary-100 {
  color: var(--pf-secondary-color-100);
}

.bg-secondary-100 {
  background-color: var(--pf-secondary-color-100);
}

.color-secondary-150 {
  color: var(--pf-secondary-color-150);
}

.bg-secondary-150 {
  background-color: var(--pf-secondary-color-150);
}

.color-secondary-200 {
  color: var(--pf-secondary-color-200);
}

.bg-secondary-200 {
  background-color: var(--pf-secondary-color-200);
}

.color-secondary-250 {
  color: var(--pf-secondary-color-250);
}

.bg-secondary-250 {
  background-color: var(--pf-secondary-color-250);
}

.color-secondary-300 {
  color: var(--pf-secondary-color-300);
}

.bg-secondary-300 {
  background-color: var(--pf-secondary-color-300);
}

.color-secondary-350 {
  color: var(--pf-secondary-color-350);
}

.bg-secondary-350 {
  background-color: var(--pf-secondary-color-350);
}

.color-secondary-400 {
  color: var(--pf-secondary-color-400);
}

.bg-secondary-400 {
  background-color: var(--pf-secondary-color-400);
}

.color-secondary-450 {
  color: var(--pf-secondary-color-450);
}

.bg-secondary-450 {
  background-color: var(--pf-secondary-color-450);
}

.color-secondary-500 {
  color: var(--pf-secondary-color-500);
}

.bg-secondary-500 {
  background-color: var(--pf-secondary-color-500);
}

.color-secondary-550 {
  color: var(--pf-secondary-color-550);
}

.bg-secondary-550 {
  background-color: var(--pf-secondary-color-550);
}

.color-secondary-600 {
  color: var(--pf-secondary-color-600);
}

.bg-secondary-600 {
  background-color: var(--pf-secondary-color-600);
}

.color-secondary-650 {
  color: var(--pf-secondary-color-650);
}

.bg-secondary-650 {
  background-color: var(--pf-secondary-color-650);
}

.color-secondary-700 {
  color: var(--pf-secondary-color-700);
}

.bg-secondary-700 {
  background-color: var(--pf-secondary-color-700);
}

.color-secondary-750 {
  color: var(--pf-secondary-color-750);
}

.bg-secondary-750 {
  background-color: var(--pf-secondary-color-750);
}

.color-secondary-800 {
  color: var(--pf-secondary-color-800);
}

.bg-secondary-800 {
  background-color: var(--pf-secondary-color-800);
}

.color-secondary-850 {
  color: var(--pf-secondary-color-850);
}

.bg-secondary-850 {
  background-color: var(--pf-secondary-color-850);
}

.color-secondary-900 {
  color: var(--pf-secondary-color-900);
}

.bg-secondary-900 {
  background-color: var(--pf-secondary-color-900);
}

.color-secondary-950 {
  color: var(--pf-secondary-color-950);
}

.bg-secondary-950 {
  background-color: var(--pf-secondary-color-950);
}

.color-secondary-1000 {
  color: var(--pf-secondary-color-1000);
}

.bg-secondary-1000 {
  background-color: var(--pf-secondary-color-1000);
}

.color-tertiary {
  color: var(--pf-tertiary-color);
}

.bg-tertiary {
  background-color: var(--pf-tertiary-color);
}

.color-tertiary-50 {
  color: var(--pf-tertiary-color-50);
}

.bg-tertiary-50 {
  background-color: var(--pf-tertiary-color-50);
}

.color-tertiary-100 {
  color: var(--pf-tertiary-color-100);
}

.bg-tertiary-100 {
  background-color: var(--pf-tertiary-color-100);
}

.color-tertiary-150 {
  color: var(--pf-tertiary-color-150);
}

.bg-tertiary-150 {
  background-color: var(--pf-tertiary-color-150);
}

.color-tertiary-200 {
  color: var(--pf-tertiary-color-200);
}

.bg-tertiary-200 {
  background-color: var(--pf-tertiary-color-200);
}

.color-tertiary-250 {
  color: var(--pf-tertiary-color-250);
}

.bg-tertiary-250 {
  background-color: var(--pf-tertiary-color-250);
}

.color-tertiary-300 {
  color: var(--pf-tertiary-color-300);
}

.bg-tertiary-300 {
  background-color: var(--pf-tertiary-color-300);
}

.color-tertiary-350 {
  color: var(--pf-tertiary-color-350);
}

.bg-tertiary-350 {
  background-color: var(--pf-tertiary-color-350);
}

.color-tertiary-400 {
  color: var(--pf-tertiary-color-400);
}

.bg-tertiary-400 {
  background-color: var(--pf-tertiary-color-400);
}

.color-tertiary-450 {
  color: var(--pf-tertiary-color-450);
}

.bg-tertiary-450 {
  background-color: var(--pf-tertiary-color-450);
}

.color-tertiary-500 {
  color: var(--pf-tertiary-color-500);
}

.bg-tertiary-500 {
  background-color: var(--pf-tertiary-color-500);
}

.color-tertiary-550 {
  color: var(--pf-tertiary-color-550);
}

.bg-tertiary-550 {
  background-color: var(--pf-tertiary-color-550);
}

.color-tertiary-600 {
  color: var(--pf-tertiary-color-600);
}

.bg-tertiary-600 {
  background-color: var(--pf-tertiary-color-600);
}

.color-tertiary-650 {
  color: var(--pf-tertiary-color-650);
}

.bg-tertiary-650 {
  background-color: var(--pf-tertiary-color-650);
}

.color-tertiary-700 {
  color: var(--pf-tertiary-color-700);
}

.bg-tertiary-700 {
  background-color: var(--pf-tertiary-color-700);
}

.color-tertiary-750 {
  color: var(--pf-tertiary-color-750);
}

.bg-tertiary-750 {
  background-color: var(--pf-tertiary-color-750);
}

.color-tertiary-800 {
  color: var(--pf-tertiary-color-800);
}

.bg-tertiary-800 {
  background-color: var(--pf-tertiary-color-800);
}

.color-tertiary-850 {
  color: var(--pf-tertiary-color-850);
}

.bg-tertiary-850 {
  background-color: var(--pf-tertiary-color-850);
}

.color-tertiary-900 {
  color: var(--pf-tertiary-color-900);
}

.bg-tertiary-900 {
  background-color: var(--pf-tertiary-color-900);
}

.color-tertiary-950 {
  color: var(--pf-tertiary-color-950);
}

.bg-tertiary-950 {
  background-color: var(--pf-tertiary-color-950);
}

.color-tertiary-1000 {
  color: var(--pf-tertiary-color-1000);
}

.bg-tertiary-1000 {
  background-color: var(--pf-tertiary-color-1000);
}

.color-gray {
  color: var(--pf-gray-color);
}

.bg-gray {
  background-color: var(--pf-gray-color);
}

.color-gray-50 {
  color: var(--pf-gray-color-50);
}

.bg-gray-50 {
  background-color: var(--pf-gray-color-50);
}

.color-gray-100 {
  color: var(--pf-gray-color-100);
}

.bg-gray-100 {
  background-color: var(--pf-gray-color-100);
}

.color-gray-150 {
  color: var(--pf-gray-color-150);
}

.bg-gray-150 {
  background-color: var(--pf-gray-color-150);
}

.color-gray-200 {
  color: var(--pf-gray-color-200);
}

.bg-gray-200 {
  background-color: var(--pf-gray-color-200);
}

.color-gray-250 {
  color: var(--pf-gray-color-250);
}

.bg-gray-250 {
  background-color: var(--pf-gray-color-250);
}

.color-gray-300 {
  color: var(--pf-gray-color-300);
}

.bg-gray-300 {
  background-color: var(--pf-gray-color-300);
}

.color-gray-350 {
  color: var(--pf-gray-color-350);
}

.bg-gray-350 {
  background-color: var(--pf-gray-color-350);
}

.color-gray-400 {
  color: var(--pf-gray-color-400);
}

.bg-gray-400 {
  background-color: var(--pf-gray-color-400);
}

.color-gray-450 {
  color: var(--pf-gray-color-450);
}

.bg-gray-450 {
  background-color: var(--pf-gray-color-450);
}

.color-gray-500 {
  color: var(--pf-gray-color-500);
}

.bg-gray-500 {
  background-color: var(--pf-gray-color-500);
}

.color-gray-550 {
  color: var(--pf-gray-color-550);
}

.bg-gray-550 {
  background-color: var(--pf-gray-color-550);
}

.color-gray-600 {
  color: var(--pf-gray-color-600);
}

.bg-gray-600 {
  background-color: var(--pf-gray-color-600);
}

.color-gray-650 {
  color: var(--pf-gray-color-650);
}

.bg-gray-650 {
  background-color: var(--pf-gray-color-650);
}

.color-gray-700 {
  color: var(--pf-gray-color-700);
}

.bg-gray-700 {
  background-color: var(--pf-gray-color-700);
}

.color-gray-750 {
  color: var(--pf-gray-color-750);
}

.bg-gray-750 {
  background-color: var(--pf-gray-color-750);
}

.color-gray-800 {
  color: var(--pf-gray-color-800);
}

.bg-gray-800 {
  background-color: var(--pf-gray-color-800);
}

.color-gray-850 {
  color: var(--pf-gray-color-850);
}

.bg-gray-850 {
  background-color: var(--pf-gray-color-850);
}

.color-gray-900 {
  color: var(--pf-gray-color-900);
}

.bg-gray-900 {
  background-color: var(--pf-gray-color-900);
}

.color-gray-950 {
  color: var(--pf-gray-color-950);
}

.bg-gray-950 {
  background-color: var(--pf-gray-color-950);
}

.color-gray-1000 {
  color: var(--pf-gray-color-1000);
}

.bg-gray-1000 {
  background-color: var(--pf-gray-color-1000);
}

.color-red {
  color: var(--pf-red-color);
}

.bg-red {
  background-color: var(--pf-red-color);
}

.color-red-50 {
  color: var(--pf-red-color-50);
}

.bg-red-50 {
  background-color: var(--pf-red-color-50);
}

.color-red-100 {
  color: var(--pf-red-color-100);
}

.bg-red-100 {
  background-color: var(--pf-red-color-100);
}

.color-red-150 {
  color: var(--pf-red-color-150);
}

.bg-red-150 {
  background-color: var(--pf-red-color-150);
}

.color-red-200 {
  color: var(--pf-red-color-200);
}

.bg-red-200 {
  background-color: var(--pf-red-color-200);
}

.color-red-250 {
  color: var(--pf-red-color-250);
}

.bg-red-250 {
  background-color: var(--pf-red-color-250);
}

.color-red-300 {
  color: var(--pf-red-color-300);
}

.bg-red-300 {
  background-color: var(--pf-red-color-300);
}

.color-red-350 {
  color: var(--pf-red-color-350);
}

.bg-red-350 {
  background-color: var(--pf-red-color-350);
}

.color-red-400 {
  color: var(--pf-red-color-400);
}

.bg-red-400 {
  background-color: var(--pf-red-color-400);
}

.color-red-450 {
  color: var(--pf-red-color-450);
}

.bg-red-450 {
  background-color: var(--pf-red-color-450);
}

.color-red-500 {
  color: var(--pf-red-color-500);
}

.bg-red-500 {
  background-color: var(--pf-red-color-500);
}

.color-red-550 {
  color: var(--pf-red-color-550);
}

.bg-red-550 {
  background-color: var(--pf-red-color-550);
}

.color-red-600 {
  color: var(--pf-red-color-600);
}

.bg-red-600 {
  background-color: var(--pf-red-color-600);
}

.color-red-650 {
  color: var(--pf-red-color-650);
}

.bg-red-650 {
  background-color: var(--pf-red-color-650);
}

.color-red-700 {
  color: var(--pf-red-color-700);
}

.bg-red-700 {
  background-color: var(--pf-red-color-700);
}

.color-red-750 {
  color: var(--pf-red-color-750);
}

.bg-red-750 {
  background-color: var(--pf-red-color-750);
}

.color-red-800 {
  color: var(--pf-red-color-800);
}

.bg-red-800 {
  background-color: var(--pf-red-color-800);
}

.color-red-850 {
  color: var(--pf-red-color-850);
}

.bg-red-850 {
  background-color: var(--pf-red-color-850);
}

.color-red-900 {
  color: var(--pf-red-color-900);
}

.bg-red-900 {
  background-color: var(--pf-red-color-900);
}

.color-red-950 {
  color: var(--pf-red-color-950);
}

.bg-red-950 {
  background-color: var(--pf-red-color-950);
}

.color-red-1000 {
  color: var(--pf-red-color-1000);
}

.bg-red-1000 {
  background-color: var(--pf-red-color-1000);
}

.color-orange {
  color: var(--pf-orange-color);
}

.bg-orange {
  background-color: var(--pf-orange-color);
}

.color-orange-50 {
  color: var(--pf-orange-color-50);
}

.bg-orange-50 {
  background-color: var(--pf-orange-color-50);
}

.color-orange-100 {
  color: var(--pf-orange-color-100);
}

.bg-orange-100 {
  background-color: var(--pf-orange-color-100);
}

.color-orange-150 {
  color: var(--pf-orange-color-150);
}

.bg-orange-150 {
  background-color: var(--pf-orange-color-150);
}

.color-orange-200 {
  color: var(--pf-orange-color-200);
}

.bg-orange-200 {
  background-color: var(--pf-orange-color-200);
}

.color-orange-250 {
  color: var(--pf-orange-color-250);
}

.bg-orange-250 {
  background-color: var(--pf-orange-color-250);
}

.color-orange-300 {
  color: var(--pf-orange-color-300);
}

.bg-orange-300 {
  background-color: var(--pf-orange-color-300);
}

.color-orange-350 {
  color: var(--pf-orange-color-350);
}

.bg-orange-350 {
  background-color: var(--pf-orange-color-350);
}

.color-orange-400 {
  color: var(--pf-orange-color-400);
}

.bg-orange-400 {
  background-color: var(--pf-orange-color-400);
}

.color-orange-450 {
  color: var(--pf-orange-color-450);
}

.bg-orange-450 {
  background-color: var(--pf-orange-color-450);
}

.color-orange-500 {
  color: var(--pf-orange-color-500);
}

.bg-orange-500 {
  background-color: var(--pf-orange-color-500);
}

.color-orange-550 {
  color: var(--pf-orange-color-550);
}

.bg-orange-550 {
  background-color: var(--pf-orange-color-550);
}

.color-orange-600 {
  color: var(--pf-orange-color-600);
}

.bg-orange-600 {
  background-color: var(--pf-orange-color-600);
}

.color-orange-650 {
  color: var(--pf-orange-color-650);
}

.bg-orange-650 {
  background-color: var(--pf-orange-color-650);
}

.color-orange-700 {
  color: var(--pf-orange-color-700);
}

.bg-orange-700 {
  background-color: var(--pf-orange-color-700);
}

.color-orange-750 {
  color: var(--pf-orange-color-750);
}

.bg-orange-750 {
  background-color: var(--pf-orange-color-750);
}

.color-orange-800 {
  color: var(--pf-orange-color-800);
}

.bg-orange-800 {
  background-color: var(--pf-orange-color-800);
}

.color-orange-850 {
  color: var(--pf-orange-color-850);
}

.bg-orange-850 {
  background-color: var(--pf-orange-color-850);
}

.color-orange-900 {
  color: var(--pf-orange-color-900);
}

.bg-orange-900 {
  background-color: var(--pf-orange-color-900);
}

.color-orange-950 {
  color: var(--pf-orange-color-950);
}

.bg-orange-950 {
  background-color: var(--pf-orange-color-950);
}

.color-orange-1000 {
  color: var(--pf-orange-color-1000);
}

.bg-orange-1000 {
  background-color: var(--pf-orange-color-1000);
}

.color-yellow {
  color: var(--pf-yellow-color);
}

.bg-yellow {
  background-color: var(--pf-yellow-color);
}

.color-yellow-50 {
  color: var(--pf-yellow-color-50);
}

.bg-yellow-50 {
  background-color: var(--pf-yellow-color-50);
}

.color-yellow-100 {
  color: var(--pf-yellow-color-100);
}

.bg-yellow-100 {
  background-color: var(--pf-yellow-color-100);
}

.color-yellow-150 {
  color: var(--pf-yellow-color-150);
}

.bg-yellow-150 {
  background-color: var(--pf-yellow-color-150);
}

.color-yellow-200 {
  color: var(--pf-yellow-color-200);
}

.bg-yellow-200 {
  background-color: var(--pf-yellow-color-200);
}

.color-yellow-250 {
  color: var(--pf-yellow-color-250);
}

.bg-yellow-250 {
  background-color: var(--pf-yellow-color-250);
}

.color-yellow-300 {
  color: var(--pf-yellow-color-300);
}

.bg-yellow-300 {
  background-color: var(--pf-yellow-color-300);
}

.color-yellow-350 {
  color: var(--pf-yellow-color-350);
}

.bg-yellow-350 {
  background-color: var(--pf-yellow-color-350);
}

.color-yellow-400 {
  color: var(--pf-yellow-color-400);
}

.bg-yellow-400 {
  background-color: var(--pf-yellow-color-400);
}

.color-yellow-450 {
  color: var(--pf-yellow-color-450);
}

.bg-yellow-450 {
  background-color: var(--pf-yellow-color-450);
}

.color-yellow-500 {
  color: var(--pf-yellow-color-500);
}

.bg-yellow-500 {
  background-color: var(--pf-yellow-color-500);
}

.color-yellow-550 {
  color: var(--pf-yellow-color-550);
}

.bg-yellow-550 {
  background-color: var(--pf-yellow-color-550);
}

.color-yellow-600 {
  color: var(--pf-yellow-color-600);
}

.bg-yellow-600 {
  background-color: var(--pf-yellow-color-600);
}

.color-yellow-650 {
  color: var(--pf-yellow-color-650);
}

.bg-yellow-650 {
  background-color: var(--pf-yellow-color-650);
}

.color-yellow-700 {
  color: var(--pf-yellow-color-700);
}

.bg-yellow-700 {
  background-color: var(--pf-yellow-color-700);
}

.color-yellow-750 {
  color: var(--pf-yellow-color-750);
}

.bg-yellow-750 {
  background-color: var(--pf-yellow-color-750);
}

.color-yellow-800 {
  color: var(--pf-yellow-color-800);
}

.bg-yellow-800 {
  background-color: var(--pf-yellow-color-800);
}

.color-yellow-850 {
  color: var(--pf-yellow-color-850);
}

.bg-yellow-850 {
  background-color: var(--pf-yellow-color-850);
}

.color-yellow-900 {
  color: var(--pf-yellow-color-900);
}

.bg-yellow-900 {
  background-color: var(--pf-yellow-color-900);
}

.color-yellow-950 {
  color: var(--pf-yellow-color-950);
}

.bg-yellow-950 {
  background-color: var(--pf-yellow-color-950);
}

.color-yellow-1000 {
  color: var(--pf-yellow-color-1000);
}

.bg-yellow-1000 {
  background-color: var(--pf-yellow-color-1000);
}

.color-green {
  color: var(--pf-green-color);
}

.bg-green {
  background-color: var(--pf-green-color);
}

.color-green-50 {
  color: var(--pf-green-color-50);
}

.bg-green-50 {
  background-color: var(--pf-green-color-50);
}

.color-green-100 {
  color: var(--pf-green-color-100);
}

.bg-green-100 {
  background-color: var(--pf-green-color-100);
}

.color-green-150 {
  color: var(--pf-green-color-150);
}

.bg-green-150 {
  background-color: var(--pf-green-color-150);
}

.color-green-200 {
  color: var(--pf-green-color-200);
}

.bg-green-200 {
  background-color: var(--pf-green-color-200);
}

.color-green-250 {
  color: var(--pf-green-color-250);
}

.bg-green-250 {
  background-color: var(--pf-green-color-250);
}

.color-green-300 {
  color: var(--pf-green-color-300);
}

.bg-green-300 {
  background-color: var(--pf-green-color-300);
}

.color-green-350 {
  color: var(--pf-green-color-350);
}

.bg-green-350 {
  background-color: var(--pf-green-color-350);
}

.color-green-400 {
  color: var(--pf-green-color-400);
}

.bg-green-400 {
  background-color: var(--pf-green-color-400);
}

.color-green-450 {
  color: var(--pf-green-color-450);
}

.bg-green-450 {
  background-color: var(--pf-green-color-450);
}

.color-green-500 {
  color: var(--pf-green-color-500);
}

.bg-green-500 {
  background-color: var(--pf-green-color-500);
}

.color-green-550 {
  color: var(--pf-green-color-550);
}

.bg-green-550 {
  background-color: var(--pf-green-color-550);
}

.color-green-600 {
  color: var(--pf-green-color-600);
}

.bg-green-600 {
  background-color: var(--pf-green-color-600);
}

.color-green-650 {
  color: var(--pf-green-color-650);
}

.bg-green-650 {
  background-color: var(--pf-green-color-650);
}

.color-green-700 {
  color: var(--pf-green-color-700);
}

.bg-green-700 {
  background-color: var(--pf-green-color-700);
}

.color-green-750 {
  color: var(--pf-green-color-750);
}

.bg-green-750 {
  background-color: var(--pf-green-color-750);
}

.color-green-800 {
  color: var(--pf-green-color-800);
}

.bg-green-800 {
  background-color: var(--pf-green-color-800);
}

.color-green-850 {
  color: var(--pf-green-color-850);
}

.bg-green-850 {
  background-color: var(--pf-green-color-850);
}

.color-green-900 {
  color: var(--pf-green-color-900);
}

.bg-green-900 {
  background-color: var(--pf-green-color-900);
}

.color-green-950 {
  color: var(--pf-green-color-950);
}

.bg-green-950 {
  background-color: var(--pf-green-color-950);
}

.color-green-1000 {
  color: var(--pf-green-color-1000);
}

.bg-green-1000 {
  background-color: var(--pf-green-color-1000);
}

.color-purple {
  color: var(--pf-purple-color);
}

.bg-purple {
  background-color: var(--pf-purple-color);
}

.color-purple-50 {
  color: var(--pf-purple-color-50);
}

.bg-purple-50 {
  background-color: var(--pf-purple-color-50);
}

.color-purple-100 {
  color: var(--pf-purple-color-100);
}

.bg-purple-100 {
  background-color: var(--pf-purple-color-100);
}

.color-purple-150 {
  color: var(--pf-purple-color-150);
}

.bg-purple-150 {
  background-color: var(--pf-purple-color-150);
}

.color-purple-200 {
  color: var(--pf-purple-color-200);
}

.bg-purple-200 {
  background-color: var(--pf-purple-color-200);
}

.color-purple-250 {
  color: var(--pf-purple-color-250);
}

.bg-purple-250 {
  background-color: var(--pf-purple-color-250);
}

.color-purple-300 {
  color: var(--pf-purple-color-300);
}

.bg-purple-300 {
  background-color: var(--pf-purple-color-300);
}

.color-purple-350 {
  color: var(--pf-purple-color-350);
}

.bg-purple-350 {
  background-color: var(--pf-purple-color-350);
}

.color-purple-400 {
  color: var(--pf-purple-color-400);
}

.bg-purple-400 {
  background-color: var(--pf-purple-color-400);
}

.color-purple-450 {
  color: var(--pf-purple-color-450);
}

.bg-purple-450 {
  background-color: var(--pf-purple-color-450);
}

.color-purple-500 {
  color: var(--pf-purple-color-500);
}

.bg-purple-500 {
  background-color: var(--pf-purple-color-500);
}

.color-purple-550 {
  color: var(--pf-purple-color-550);
}

.bg-purple-550 {
  background-color: var(--pf-purple-color-550);
}

.color-purple-600 {
  color: var(--pf-purple-color-600);
}

.bg-purple-600 {
  background-color: var(--pf-purple-color-600);
}

.color-purple-650 {
  color: var(--pf-purple-color-650);
}

.bg-purple-650 {
  background-color: var(--pf-purple-color-650);
}

.color-purple-700 {
  color: var(--pf-purple-color-700);
}

.bg-purple-700 {
  background-color: var(--pf-purple-color-700);
}

.color-purple-750 {
  color: var(--pf-purple-color-750);
}

.bg-purple-750 {
  background-color: var(--pf-purple-color-750);
}

.color-purple-800 {
  color: var(--pf-purple-color-800);
}

.bg-purple-800 {
  background-color: var(--pf-purple-color-800);
}

.color-purple-850 {
  color: var(--pf-purple-color-850);
}

.bg-purple-850 {
  background-color: var(--pf-purple-color-850);
}

.color-purple-900 {
  color: var(--pf-purple-color-900);
}

.bg-purple-900 {
  background-color: var(--pf-purple-color-900);
}

.color-purple-950 {
  color: var(--pf-purple-color-950);
}

.bg-purple-950 {
  background-color: var(--pf-purple-color-950);
}

.color-purple-1000 {
  color: var(--pf-purple-color-1000);
}

.bg-purple-1000 {
  background-color: var(--pf-purple-color-1000);
}

.color-success {
  color: var(--pf-success-color);
}

.bg-success {
  background-color: var(--pf-success-color);
}

.color-info {
  color: var(--pf-info-color);
}

.bg-info {
  background-color: var(--pf-info-color);
}

.color-neutral {
  color: var(--pf-neutral-color);
}

.bg-neutral {
  background-color: var(--pf-neutral-color);
}

.color-warning {
  color: var(--pf-warning-color);
}

.bg-warning {
  background-color: var(--pf-warning-color);
}

.color-error {
  color: var(--pf-error-color);
}

.bg-error {
  background-color: var(--pf-error-color);
}

.color-brand {
  color: var(--pf-brand-color);
}

.bg-brand {
  background-color: var(--pf-brand-color);
}

.link-color {
  color: var(--pf-link-color);
}

.active-link-color {
  color: var(--pf-active-link-color);
}

.border {
  border: solid 1px;
  border-color: var(--pf-border-color);
  border-radius: var(--pf-rounded);
}

.border-thin {
  border-style: solid;
  border-width: var(--pf-border-thin);
}

.border-t-thin {
  border-style: solid;
  border-top-width: var(--pf-border-thin);
}

.border-b-thin {
  border-style: solid;
  border-bottom-width: var(--pf-border-thin);
}

.border-l-thin {
  border-style: solid;
  border-left-width: var(--pf-border-thin);
}

.border-r-thin {
  border-style: solid;
  border-right-width: var(--pf-border-thin);
}

.border-sm {
  border-style: solid;
  border-width: var(--pf-border-sm);
}

.border-t-sm {
  border-style: solid;
  border-top-width: var(--pf-border-sm);
}

.border-b-sm {
  border-style: solid;
  border-bottom-width: var(--pf-border-sm);
}

.border-l-sm {
  border-style: solid;
  border-left-width: var(--pf-border-sm);
}

.border-r-sm {
  border-style: solid;
  border-right-width: var(--pf-border-sm);
}

.border-md {
  border-style: solid;
  border-width: var(--pf-border-md);
}

.border-t-md {
  border-style: solid;
  border-top-width: var(--pf-border-md);
}

.border-b-md {
  border-style: solid;
  border-bottom-width: var(--pf-border-md);
}

.border-l-md {
  border-style: solid;
  border-left-width: var(--pf-border-md);
}

.border-r-md {
  border-style: solid;
  border-right-width: var(--pf-border-md);
}

.border-lg {
  border-style: solid;
  border-width: var(--pf-border-lg);
}

.border-t-lg {
  border-style: solid;
  border-top-width: var(--pf-border-lg);
}

.border-b-lg {
  border-style: solid;
  border-bottom-width: var(--pf-border-lg);
}

.border-l-lg {
  border-style: solid;
  border-left-width: var(--pf-border-lg);
}

.border-r-lg {
  border-style: solid;
  border-right-width: var(--pf-border-lg);
}

.border-0 {
  border-style: solid;
  border-width: 0;
}

.border-t-0 {
  border-style: solid;
  border-top-width: 0;
}

.border-b-0 {
  border-style: solid;
  border-bottom-width: 0;
}

.border-l-0 {
  border-style: solid;
  border-left-width: 0;
}

.border-r-0 {
  border-style: solid;
  border-right-width: 0;
}

.border-xl {
  border-style: solid;
  border-width: var(--pf-border-xl);
}

.border-t-xl {
  border-style: solid;
  border-top-width: var(--pf-border-xl);
}

.border-b-xl {
  border-style: solid;
  border-bottom-width: var(--pf-border-xl);
}

.border-l-xl {
  border-style: solid;
  border-left-width: var(--pf-border-xl);
}

.border-r-xl {
  border-style: solid;
  border-right-width: var(--pf-border-xl);
}

.border-dashed {
  border-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

.border-double {
  border-style: double;
}

.border-solid {
  border-style: solid;
}

.border-primary {
  border-color: var(--pf-primary-color);
}

.border-primary-100 {
  border-color: var(--pf-primary-color-100);
}

.border-primary-200 {
  border-color: var(--pf-primary-color-200);
}

.border-primary-300 {
  border-color: var(--pf-primary-color-300);
}

.border-primary-400 {
  border-color: var(--pf-primary-color-400);
}

.border-primary-500 {
  border-color: var(--pf-primary-color-500);
}

.border-primary-600 {
  border-color: var(--pf-primary-color-600);
}

.border-primary-700 {
  border-color: var(--pf-primary-color-700);
}

.border-primary-800 {
  border-color: var(--pf-primary-color-800);
}

.border-primary-900 {
  border-color: var(--pf-primary-color-900);
}

.border-secondary {
  border-color: var(--pf-secondary-color);
}

.border-secondary-100 {
  border-color: var(--pf-secondary-color-100);
}

.border-secondary-200 {
  border-color: var(--pf-secondary-color-200);
}

.border-secondary-300 {
  border-color: var(--pf-secondary-color-300);
}

.border-secondary-400 {
  border-color: var(--pf-secondary-color-400);
}

.border-secondary-500 {
  border-color: var(--pf-secondary-color-500);
}

.border-secondary-600 {
  border-color: var(--pf-secondary-color-600);
}

.border-secondary-700 {
  border-color: var(--pf-secondary-color-700);
}

.border-secondary-800 {
  border-color: var(--pf-secondary-color-800);
}

.border-secondary-900 {
  border-color: var(--pf-secondary-color-900);
}

.border-gray {
  border-color: var(--pf-gray-color);
}

.border-gray-100 {
  border-color: var(--pf-gray-color-100);
}

.border-gray-200 {
  border-color: var(--pf-gray-color-200);
}

.border-gray-300 {
  border-color: var(--pf-gray-color-300);
}

.border-gray-400 {
  border-color: var(--pf-gray-color-400);
}

.border-gray-500 {
  border-color: var(--pf-gray-color-500);
}

.border-gray-600 {
  border-color: var(--pf-gray-color-600);
}

.border-gray-700 {
  border-color: var(--pf-gray-color-700);
}

.border-gray-800 {
  border-color: var(--pf-gray-color-800);
}

.border-gray-900 {
  border-color: var(--pf-gray-color-900);
}

.border-steel {
  border-color: var(--pf-steel-color);
}

.border-steel-100 {
  border-color: var(--pf-steel-color-100);
}

.border-steel-200 {
  border-color: var(--pf-steel-color-200);
}

.border-steel-300 {
  border-color: var(--pf-steel-color-300);
}

.border-steel-400 {
  border-color: var(--pf-steel-color-400);
}

.border-steel-500 {
  border-color: var(--pf-steel-color-500);
}

.border-steel-600 {
  border-color: var(--pf-steel-color-600);
}

.border-steel-700 {
  border-color: var(--pf-steel-color-700);
}

.border-steel-800 {
  border-color: var(--pf-steel-color-800);
}

.border-steel-900 {
  border-color: var(--pf-steel-color-900);
}

.border-green {
  border-color: var(--pf-green-color);
}

.border-green-100 {
  border-color: var(--pf-green-color-100);
}

.border-green-200 {
  border-color: var(--pf-green-color-200);
}

.border-green-300 {
  border-color: var(--pf-green-color-300);
}

.border-green-400 {
  border-color: var(--pf-green-color-400);
}

.border-green-500 {
  border-color: var(--pf-green-color-500);
}

.border-green-600 {
  border-color: var(--pf-green-color-600);
}

.border-green-700 {
  border-color: var(--pf-green-color-700);
}

.border-green-800 {
  border-color: var(--pf-green-color-800);
}

.border-green-900 {
  border-color: var(--pf-green-color-900);
}

.border-orange {
  border-color: var(--pf-orange-color);
}

.border-orange-100 {
  border-color: var(--pf-orange-color-100);
}

.border-orange-200 {
  border-color: var(--pf-orange-color-200);
}

.border-orange-300 {
  border-color: var(--pf-orange-color-300);
}

.border-orange-400 {
  border-color: var(--pf-orange-color-400);
}

.border-orange-500 {
  border-color: var(--pf-orange-color-500);
}

.border-orange-600 {
  border-color: var(--pf-orange-color-600);
}

.border-orange-700 {
  border-color: var(--pf-orange-color-700);
}

.border-orange-800 {
  border-color: var(--pf-orange-color-800);
}

.border-orange-900 {
  border-color: var(--pf-orange-color-900);
}

.border-red {
  border-color: var(--pf-red-color);
}

.border-red-100 {
  border-color: var(--pf-red-color-100);
}

.border-red-200 {
  border-color: var(--pf-red-color-200);
}

.border-red-300 {
  border-color: var(--pf-red-color-300);
}

.border-red-400 {
  border-color: var(--pf-red-color-400);
}

.border-red-500 {
  border-color: var(--pf-red-color-500);
}

.border-red-600 {
  border-color: var(--pf-red-color-600);
}

.border-red-700 {
  border-color: var(--pf-red-color-700);
}

.border-red-800 {
  border-color: var(--pf-red-color-800);
}

.border-red-900 {
  border-color: var(--pf-red-color-900);
}

.rounded {
  border-radius: var(--pf-rounded);
}

.rounded-0 {
  border-radius: var(--pf-rounded-0);
}

.rounded-sm {
  border-radius: var(--pf-rounded-sm);
}

.rounded-lg {
  border-radius: var(--pf-rounded-md);
}

.rounded-md {
  border-radius: var(--pf-rounded-lg);
}

.rounded-xl {
  border-radius: var(--pf-rounded-xl);
}

.rounded-pill {
  border-radius: var(--pf-rounded-pill);
}

.rounded-circle {
  border-radius: var(--pf-rounded-circle);
}

.rounded-shaped {
  border-radius: var(--pf-rounded-shaped);
}

.pa-0 {
  padding: var(--pf-padding-0);
}

.pa-1 {
  padding: var(--pf-padding-1);
}

.pa-2 {
  padding: var(--pf-padding-2);
}

.pa-3 {
  padding: var(--pf-padding-3);
}

.pa-4 {
  padding: var(--pf-padding-4);
}

.pa-5 {
  padding: var(--pf-padding-5);
}

.pa-6 {
  padding: var(--pf-padding-6);
}

.pa-7 {
  padding: var(--pf-padding-7);
}

.pa-8 {
  padding: var(--pf-padding-8);
}

.pa-9 {
  padding: var(--pf-padding-9);
}

.pa-10 {
  padding: var(--pf-padding-10);
}

.pa-11 {
  padding: var(--pf-padding-11);
}

.pa-12 {
  padding: var(--pf-padding-12);
}

.pa-13 {
  padding: var(--pf-padding-13);
}

.pa-14 {
  padding: var(--pf-padding-14);
}

.pa-15 {
  padding: var(--pf-padding-15);
}

.pa-16 {
  padding: var(--pf-padding-16);
}

.px-0 {
  padding-left: var(--pf-padding-0);
  padding-right: var(--pf-padding-0);
}

.px-1 {
  padding-left: var(--pf-padding-1);
  padding-right: var(--pf-padding-1);
}

.px-2 {
  padding-left: var(--pf-padding-2);
  padding-right: var(--pf-padding-2);
}

.px-3 {
  padding-left: var(--pf-padding-3);
  padding-right: var(--pf-padding-3);
}

.px-4 {
  padding-left: var(--pf-padding-4);
  padding-right: var(--pf-padding-4);
}

.px-5 {
  padding-left: var(--pf-padding-5);
  padding-right: var(--pf-padding-5);
}

.px-6 {
  padding-left: var(--pf-padding-6);
  padding-right: var(--pf-padding-6);
}

.px-7 {
  padding-left: var(--pf-padding-7);
  padding-right: var(--pf-padding-7);
}

.px-8 {
  padding-left: var(--pf-padding-8);
  padding-right: var(--pf-padding-8);
}

.px-9 {
  padding-left: var(--pf-padding-9);
  padding-right: var(--pf-padding-9);
}

.px-10 {
  padding-left: var(--pf-padding-10);
  padding-right: var(--pf-padding-10);
}

.px-11 {
  padding-left: var(--pf-padding-11);
  padding-right: var(--pf-padding-11);
}

.px-12 {
  padding-left: var(--pf-padding-12);
  padding-right: var(--pf-padding-12);
}

.px-13 {
  padding-left: var(--pf-padding-13);
  padding-right: var(--pf-padding-13);
}

.px-14 {
  padding-left: var(--pf-padding-14);
  padding-right: var(--pf-padding-14);
}

.px-15 {
  padding-left: var(--pf-padding-15);
  padding-right: var(--pf-padding-15);
}

.px-16 {
  padding-left: var(--pf-padding-16);
  padding-right: var(--pf-padding-16);
}

.py-0 {
  padding-top: var(--pf-padding-0);
  padding-bottom: var(--pf-padding-0);
}

.py-1 {
  padding-top: var(--pf-padding-1);
  padding-bottom: var(--pf-padding-1);
}

.py-2 {
  padding-top: var(--pf-padding-2);
  padding-bottom: var(--pf-padding-2);
}

.py-3 {
  padding-top: var(--pf-padding-3);
  padding-bottom: var(--pf-padding-3);
}

.py-4 {
  padding-top: var(--pf-padding-4);
  padding-bottom: var(--pf-padding-4);
}

.py-5 {
  padding-top: var(--pf-padding-5);
  padding-bottom: var(--pf-padding-5);
}

.py-6 {
  padding-top: var(--pf-padding-6);
  padding-bottom: var(--pf-padding-6);
}

.py-7 {
  padding-top: var(--pf-padding-7);
  padding-bottom: var(--pf-padding-7);
}

.py-8 {
  padding-top: var(--pf-padding-8);
  padding-bottom: var(--pf-padding-8);
}

.py-9 {
  padding-top: var(--pf-padding-9);
  padding-bottom: var(--pf-padding-9);
}

.py-10 {
  padding-top: var(--pf-padding-10);
  padding-bottom: var(--pf-padding-10);
}

.py-11 {
  padding-top: var(--pf-padding-11);
  padding-bottom: var(--pf-padding-11);
}

.py-12 {
  padding-top: var(--pf-padding-12);
  padding-bottom: var(--pf-padding-12);
}

.py-13 {
  padding-top: var(--pf-padding-13);
  padding-bottom: var(--pf-padding-13);
}

.py-14 {
  padding-top: var(--pf-padding-14);
  padding-bottom: var(--pf-padding-14);
}

.py-15 {
  padding-top: var(--pf-padding-15);
  padding-bottom: var(--pf-padding-15);
}

.py-16 {
  padding-top: var(--pf-padding-16);
  padding-bottom: var(--pf-padding-16);
}

.pl-0 {
  padding-left: var(--pf-padding-0);
}

.pl-1 {
  padding-left: var(--pf-padding-1);
}

.pl-2 {
  padding-left: var(--pf-padding-2);
}

.pl-3 {
  padding-left: var(--pf-padding-3);
}

.pl-4 {
  padding-left: var(--pf-padding-4);
}

.pl-5 {
  padding-left: var(--pf-padding-5);
}

.pl-6 {
  padding-left: var(--pf-padding-6);
}

.pl-7 {
  padding-left: var(--pf-padding-7);
}

.pl-8 {
  padding-left: var(--pf-padding-8);
}

.pl-9 {
  padding-left: var(--pf-padding-9);
}

.pl-10 {
  padding-left: var(--pf-padding-10);
}

.pl-11 {
  padding-left: var(--pf-padding-11);
}

.pl-12 {
  padding-left: var(--pf-padding-12);
}

.pl-13 {
  padding-left: var(--pf-padding-13);
}

.pl-14 {
  padding-left: var(--pf-padding-14);
}

.pl-15 {
  padding-left: var(--pf-padding-15);
}

.pl-16 {
  padding-left: var(--pf-padding-16);
}

.pr-0 {
  padding-right: var(--pf-padding-0);
}

.pr-1 {
  padding-right: var(--pf-padding-1);
}

.pr-2 {
  padding-right: var(--pf-padding-2);
}

.pr-3 {
  padding-right: var(--pf-padding-3);
}

.pr-4 {
  padding-right: var(--pf-padding-4);
}

.pr-5 {
  padding-right: var(--pf-padding-5);
}

.pr-6 {
  padding-right: var(--pf-padding-6);
}

.pr-7 {
  padding-right: var(--pf-padding-7);
}

.pr-8 {
  padding-right: var(--pf-padding-8);
}

.pr-9 {
  padding-right: var(--pf-padding-9);
}

.pr-10 {
  padding-right: var(--pf-padding-10);
}

.pr-11 {
  padding-right: var(--pf-padding-11);
}

.pr-12 {
  padding-right: var(--pf-padding-12);
}

.pr-13 {
  padding-right: var(--pf-padding-13);
}

.pr-14 {
  padding-right: var(--pf-padding-14);
}

.pr-15 {
  padding-right: var(--pf-padding-15);
}

.pr-16 {
  padding-right: var(--pf-padding-16);
}

.pb-0 {
  padding-bottom: var(--pf-padding-0);
}

.pb-1 {
  padding-bottom: var(--pf-padding-1);
}

.pb-2 {
  padding-bottom: var(--pf-padding-2);
}

.pb-3 {
  padding-bottom: var(--pf-padding-3);
}

.pb-4 {
  padding-bottom: var(--pf-padding-4);
}

.pb-5 {
  padding-bottom: var(--pf-padding-5);
}

.pb-6 {
  padding-bottom: var(--pf-padding-6);
}

.pb-7 {
  padding-bottom: var(--pf-padding-7);
}

.pb-8 {
  padding-bottom: var(--pf-padding-8);
}

.pb-9 {
  padding-bottom: var(--pf-padding-9);
}

.pb-10 {
  padding-bottom: var(--pf-padding-10);
}

.pb-11 {
  padding-bottom: var(--pf-padding-11);
}

.pb-12 {
  padding-bottom: var(--pf-padding-12);
}

.pb-13 {
  padding-bottom: var(--pf-padding-13);
}

.pb-14 {
  padding-bottom: var(--pf-padding-14);
}

.pb-15 {
  padding-bottom: var(--pf-padding-15);
}

.pb-16 {
  padding-bottom: var(--pf-padding-16);
}

.pt-0 {
  padding-top: var(--pf-padding-0);
}

.pt-1 {
  padding-top: var(--pf-padding-1);
}

.pt-2 {
  padding-top: var(--pf-padding-2);
}

.pt-3 {
  padding-top: var(--pf-padding-3);
}

.pt-4 {
  padding-top: var(--pf-padding-4);
}

.pt-5 {
  padding-top: var(--pf-padding-5);
}

.pt-6 {
  padding-top: var(--pf-padding-6);
}

.pt-7 {
  padding-top: var(--pf-padding-7);
}

.pt-8 {
  padding-top: var(--pf-padding-8);
}

.pt-9 {
  padding-top: var(--pf-padding-9);
}

.pt-10 {
  padding-top: var(--pf-padding-10);
}

.pt-11 {
  padding-top: var(--pf-padding-11);
}

.pt-12 {
  padding-top: var(--pf-padding-12);
}

.pt-13 {
  padding-top: var(--pf-padding-13);
}

.pt-14 {
  padding-top: var(--pf-padding-14);
}

.pt-15 {
  padding-top: var(--pf-padding-15);
}

.pt-16 {
  padding-top: var(--pf-padding-16);
}

.ma-auto {
  margin: var(--pf-margin-auto);
}

.ma-0 {
  margin: var(--pf-margin-0);
}

.ma-1 {
  margin: var(--pf-margin-1);
}

.ma-2 {
  margin: var(--pf-margin-2);
}

.ma-3 {
  margin: var(--pf-margin-3);
}

.ma-4 {
  margin: var(--pf-margin-4);
}

.ma-5 {
  margin: var(--pf-margin-5);
}

.ma-6 {
  margin: var(--pf-margin-6);
}

.ma-7 {
  margin: var(--pf-margin-7);
}

.ma-8 {
  margin: var(--pf-margin-8);
}

.ma-9 {
  margin: var(--pf-margin-9);
}

.ma-10 {
  margin: var(--pf-margin-10);
}

.ma-11 {
  margin: var(--pf-margin-11);
}

.ma-12 {
  margin: var(--pf-margin-12);
}

.ma-13 {
  margin: var(--pf-margin-13);
}

.ma-14 {
  margin: var(--pf-margin-14);
}

.ma-15 {
  margin: var(--pf-margin-15);
}

.ma-16 {
  margin: var(--pf-margin-16);
}

.ma-n1 {
  margin: var(--pf-negative-margin-1);
}

.ma-n2 {
  margin: var(--pf-negative-margin-2);
}

.ma-n3 {
  margin: var(--pf-negative-margin-3);
}

.ma-n4 {
  margin: var(--pf-negative-margin-4);
}

.ma-n5 {
  margin: var(--pf-negative-margin-5);
}

.ma-n6 {
  margin: var(--pf-negative-margin-6);
}

.ma-n7 {
  margin: var(--pf-negative-margin-7);
}

.ma-n8 {
  margin: var(--pf-negative-margin-8);
}

.ma-n9 {
  margin: var(--pf-negative-margin-9);
}

.ma-n10 {
  margin: var(--pf-negative-margin-10);
}

.ma-n11 {
  margin: var(--pf-negative-margin-11);
}

.ma-n12 {
  margin: var(--pf-negative-margin-12);
}

.ma-n13 {
  margin: var(--pf-negative-margin-13);
}

.ma-n14 {
  margin: var(--pf-negative-margin-14);
}

.ma-n15 {
  margin: var(--pf-negative-margin-15);
}

.ma-n16 {
  margin: var(--pf-negative-margin-16);
}

.mx-auto {
  margin-left: var(--pf-margin-auto);
  margin-right: var(--pf-margin-auto);
}

.mx-0 {
  margin-left: var(--pf-margin-0);
  margin-right: var(--pf-margin-0);
}

.mx-1 {
  margin-left: var(--pf-margin-1);
  margin-right: var(--pf-margin-1);
}

.mx-2 {
  margin-left: var(--pf-margin-2);
  margin-right: var(--pf-margin-2);
}

.mx-3 {
  margin-left: var(--pf-margin-3);
  margin-right: var(--pf-margin-3);
}

.mx-4 {
  margin-left: var(--pf-margin-4);
  margin-right: var(--pf-margin-4);
}

.mx-5 {
  margin-left: var(--pf-margin-5);
  margin-right: var(--pf-margin-5);
}

.mx-6 {
  margin-left: var(--pf-margin-6);
  margin-right: var(--pf-margin-6);
}

.mx-7 {
  margin-left: var(--pf-margin-7);
  margin-right: var(--pf-margin-7);
}

.mx-8 {
  margin-left: var(--pf-margin-8);
  margin-right: var(--pf-margin-8);
}

.mx-9 {
  margin-left: var(--pf-margin-9);
  margin-right: var(--pf-margin-9);
}

.mx-10 {
  margin-left: var(--pf-margin-10);
  margin-right: var(--pf-margin-10);
}

.mx-11 {
  margin-left: var(--pf-margin-11);
  margin-right: var(--pf-margin-11);
}

.mx-12 {
  margin-left: var(--pf-margin-12);
  margin-right: var(--pf-margin-12);
}

.mx-13 {
  margin-left: var(--pf-margin-13);
  margin-right: var(--pf-margin-13);
}

.mx-14 {
  margin-left: var(--pf-margin-14);
  margin-right: var(--pf-margin-14);
}

.mx-15 {
  margin-left: var(--pf-margin-15);
  margin-right: var(--pf-margin-15);
}

.mx-16 {
  margin-left: var(--pf-margin-16);
  margin-right: var(--pf-margin-16);
}

.mx-n1 {
  margin-left: var(--pf-negative-margin-1);
  margin-right: var(--pf-negative-margin-1);
}

.mx-n2 {
  margin-left: var(--pf-negative-margin-2);
  margin-right: var(--pf-negative-margin-2);
}

.mx-n3 {
  margin-left: var(--pf-negative-margin-3);
  margin-right: var(--pf-negative-margin-3);
}

.mx-n4 {
  margin-left: var(--pf-negative-margin-4);
  margin-right: var(--pf-negative-margin-4);
}

.mx-n5 {
  margin-left: var(--pf-negative-margin-5);
  margin-right: var(--pf-negative-margin-5);
}

.mx-n6 {
  margin-left: var(--pf-negative-margin-6);
  margin-right: var(--pf-negative-margin-6);
}

.mx-n7 {
  margin-left: var(--pf-negative-margin-7);
  margin-right: var(--pf-negative-margin-7);
}

.mx-n8 {
  margin-left: var(--pf-negative-margin-8);
  margin-right: var(--pf-negative-margin-8);
}

.mx-n9 {
  margin-left: var(--pf-negative-margin-9);
  margin-right: var(--pf-negative-margin-9);
}

.mx-n10 {
  margin-left: var(--pf-negative-margin-10);
  margin-right: var(--pf-negative-margin-10);
}

.mx-n11 {
  margin-left: var(--pf-negative-margin-11);
  margin-right: var(--pf-negative-margin-11);
}

.mx-n12 {
  margin-left: var(--pf-negative-margin-12);
  margin-right: var(--pf-negative-margin-12);
}

.mx-n13 {
  margin-left: var(--pf-negative-margin-13);
  margin-right: var(--pf-negative-margin-13);
}

.mx-n14 {
  margin-left: var(--pf-negative-margin-14);
  margin-right: var(--pf-negative-margin-14);
}

.mx-n15 {
  margin-left: var(--pf-negative-margin-15);
  margin-right: var(--pf-negative-margin-15);
}

.mx-n16 {
  margin-left: var(--pf-negative-margin-16);
  margin-right: var(--pf-negative-margin-16);
}

.my-auto {
  margin-top: var(--pf-margin-auto);
  margin-bottom: var(--pf-margin-auto);
}

.my-0 {
  margin-top: var(--pf-margin-0);
  margin-bottom: var(--pf-margin-0);
}

.my-1 {
  margin-top: var(--pf-margin-1);
  margin-bottom: var(--pf-margin-1);
}

.my-2 {
  margin-top: var(--pf-margin-2);
  margin-bottom: var(--pf-margin-2);
}

.my-3 {
  margin-top: var(--pf-margin-3);
  margin-bottom: var(--pf-margin-3);
}

.my-4 {
  margin-top: var(--pf-margin-4);
  margin-bottom: var(--pf-margin-4);
}

.my-5 {
  margin-top: var(--pf-margin-5);
  margin-bottom: var(--pf-margin-5);
}

.my-6 {
  margin-top: var(--pf-margin-6);
  margin-bottom: var(--pf-margin-6);
}

.my-7 {
  margin-top: var(--pf-margin-7);
  margin-bottom: var(--pf-margin-7);
}

.my-8 {
  margin-top: var(--pf-margin-8);
  margin-bottom: var(--pf-margin-8);
}

.my-9 {
  margin-top: var(--pf-margin-9);
  margin-bottom: var(--pf-margin-9);
}

.my-10 {
  margin-top: var(--pf-margin-10);
  margin-bottom: var(--pf-margin-10);
}

.my-11 {
  margin-top: var(--pf-margin-11);
  margin-bottom: var(--pf-margin-11);
}

.my-12 {
  margin-top: var(--pf-margin-12);
  margin-bottom: var(--pf-margin-12);
}

.my-13 {
  margin-top: var(--pf-margin-13);
  margin-bottom: var(--pf-margin-13);
}

.my-14 {
  margin-top: var(--pf-margin-14);
  margin-bottom: var(--pf-margin-14);
}

.my-15 {
  margin-top: var(--pf-margin-15);
  margin-bottom: var(--pf-margin-15);
}

.my-16 {
  margin-top: var(--pf-margin-16);
  margin-bottom: var(--pf-margin-16);
}

.my-n1 {
  margin-top: var(--pf-negative-margin-1);
  margin-bottom: var(--pf-negative-margin-1);
}

.my-n2 {
  margin-top: var(--pf-negative-margin-2);
  margin-bottom: var(--pf-negative-margin-2);
}

.my-n3 {
  margin-top: var(--pf-negative-margin-3);
  margin-bottom: var(--pf-negative-margin-3);
}

.my-n4 {
  margin-top: var(--pf-negative-margin-4);
  margin-bottom: var(--pf-negative-margin-4);
}

.my-n5 {
  margin-top: var(--pf-negative-margin-5);
  margin-bottom: var(--pf-negative-margin-5);
}

.my-n6 {
  margin-top: var(--pf-negative-margin-6);
  margin-bottom: var(--pf-negative-margin-6);
}

.my-n7 {
  margin-top: var(--pf-negative-margin-7);
  margin-bottom: var(--pf-negative-margin-7);
}

.my-n8 {
  margin-top: var(--pf-negative-margin-8);
  margin-bottom: var(--pf-negative-margin-8);
}

.my-n9 {
  margin-top: var(--pf-negative-margin-9);
  margin-bottom: var(--pf-negative-margin-9);
}

.my-n10 {
  margin-top: var(--pf-negative-margin-10);
  margin-bottom: var(--pf-negative-margin-10);
}

.my-n11 {
  margin-top: var(--pf-negative-margin-11);
  margin-bottom: var(--pf-negative-margin-11);
}

.my-n12 {
  margin-top: var(--pf-negative-margin-12);
  margin-bottom: var(--pf-negative-margin-12);
}

.my-n13 {
  margin-top: var(--pf-negative-margin-13);
  margin-bottom: var(--pf-negative-margin-13);
}

.my-n14 {
  margin-top: var(--pf-negative-margin-14);
  margin-bottom: var(--pf-negative-margin-14);
}

.my-n15 {
  margin-top: var(--pf-negative-margin-15);
  margin-bottom: var(--pf-negative-margin-15);
}

.my-n16 {
  margin-top: var(--pf-negative-margin-16);
  margin-bottom: var(--pf-negative-margin-16);
}

.ml-auto {
  margin-left: var(--pf-margin-auto);
}

.ml-0 {
  margin-left: var(--pf-margin-0);
}

.ml-1 {
  margin-left: var(--pf-margin-1);
}

.ml-2 {
  margin-left: var(--pf-margin-2);
}

.ml-3 {
  margin-left: var(--pf-margin-3);
}

.ml-4 {
  margin-left: var(--pf-margin-4);
}

.ml-5 {
  margin-left: var(--pf-margin-5);
}

.ml-6 {
  margin-left: var(--pf-margin-6);
}

.ml-7 {
  margin-left: var(--pf-margin-7);
}

.ml-8 {
  margin-left: var(--pf-margin-8);
}

.ml-9 {
  margin-left: var(--pf-margin-9);
}

.ml-10 {
  margin-left: var(--pf-margin-10);
}

.ml-11 {
  margin-left: var(--pf-margin-11);
}

.ml-12 {
  margin-left: var(--pf-margin-12);
}

.ml-13 {
  margin-left: var(--pf-margin-13);
}

.ml-14 {
  margin-left: var(--pf-margin-14);
}

.ml-15 {
  margin-left: var(--pf-margin-15);
}

.ml-16 {
  margin-left: var(--pf-margin-16);
}

.ml-n1 {
  margin-left: var(--pf-negative-margin-1);
}

.ml-n2 {
  margin-left: var(--pf-negative-margin-2);
}

.ml-n3 {
  margin-left: var(--pf-negative-margin-3);
}

.ml-n4 {
  margin-left: var(--pf-negative-margin-4);
}

.ml-n5 {
  margin-left: var(--pf-negative-margin-5);
}

.ml-n6 {
  margin-left: var(--pf-negative-margin-6);
}

.ml-n7 {
  margin-left: var(--pf-negative-margin-7);
}

.ml-n8 {
  margin-left: var(--pf-negative-margin-8);
}

.ml-n9 {
  margin-left: var(--pf-negative-margin-9);
}

.ml-n10 {
  margin-left: var(--pf-negative-margin-10);
}

.ml-n11 {
  margin-left: var(--pf-negative-margin-11);
}

.ml-n12 {
  margin-left: var(--pf-negative-margin-12);
}

.ml-n13 {
  margin-left: var(--pf-negative-margin-13);
}

.ml-n14 {
  margin-left: var(--pf-negative-margin-14);
}

.ml-n15 {
  margin-left: var(--pf-negative-margin-15);
}

.ml-n16 {
  margin-left: var(--pf-negative-margin-16);
}

.mr-auto {
  margin-right: var(--pf-margin-auto);
}

.mr-0 {
  margin-right: var(--pf-margin-0);
}

.mr-1 {
  margin-right: var(--pf-margin-1);
}

.mr-2 {
  margin-right: var(--pf-margin-2);
}

.mr-3 {
  margin-right: var(--pf-margin-3);
}

.mr-4 {
  margin-right: var(--pf-margin-4);
}

.mr-5 {
  margin-right: var(--pf-margin-5);
}

.mr-6 {
  margin-right: var(--pf-margin-6);
}

.mr-7 {
  margin-right: var(--pf-margin-7);
}

.mr-8 {
  margin-right: var(--pf-margin-8);
}

.mr-9 {
  margin-right: var(--pf-margin-9);
}

.mr-10 {
  margin-right: var(--pf-margin-10);
}

.mr-11 {
  margin-right: var(--pf-margin-11);
}

.mr-12 {
  margin-right: var(--pf-margin-12);
}

.mr-13 {
  margin-right: var(--pf-margin-13);
}

.mr-14 {
  margin-right: var(--pf-margin-14);
}

.mr-15 {
  margin-right: var(--pf-margin-15);
}

.mr-16 {
  margin-right: var(--pf-margin-16);
}

.mr-n1 {
  margin-right: var(--pf-negative-margin-1);
}

.mr-n2 {
  margin-right: var(--pf-negative-margin-2);
}

.mr-n3 {
  margin-right: var(--pf-negative-margin-3);
}

.mr-n4 {
  margin-right: var(--pf-negative-margin-4);
}

.mr-n5 {
  margin-right: var(--pf-negative-margin-5);
}

.mr-n6 {
  margin-right: var(--pf-negative-margin-6);
}

.mr-n7 {
  margin-right: var(--pf-negative-margin-7);
}

.mr-n8 {
  margin-right: var(--pf-negative-margin-8);
}

.mr-n9 {
  margin-right: var(--pf-negative-margin-9);
}

.mr-n10 {
  margin-right: var(--pf-negative-margin-10);
}

.mr-n11 {
  margin-right: var(--pf-negative-margin-11);
}

.mr-n12 {
  margin-right: var(--pf-negative-margin-12);
}

.mr-n13 {
  margin-right: var(--pf-negative-margin-13);
}

.mr-n14 {
  margin-right: var(--pf-negative-margin-14);
}

.mr-n15 {
  margin-right: var(--pf-negative-margin-15);
}

.mr-n16 {
  margin-right: var(--pf-negative-margin-16);
}

.mb-auto {
  margin-bottom: var(--pf-margin-auto);
}

.mb-0 {
  margin-bottom: var(--pf-margin-0);
}

.mb-1 {
  margin-bottom: var(--pf-margin-1);
}

.mb-2 {
  margin-bottom: var(--pf-margin-2);
}

.mb-3 {
  margin-bottom: var(--pf-margin-3);
}

.mb-4 {
  margin-bottom: var(--pf-margin-4);
}

.mb-5 {
  margin-bottom: var(--pf-margin-5);
}

.mb-6 {
  margin-bottom: var(--pf-margin-6);
}

.mb-7 {
  margin-bottom: var(--pf-margin-7);
}

.mb-8 {
  margin-bottom: var(--pf-margin-8);
}

.mb-9 {
  margin-bottom: var(--pf-margin-9);
}

.mb-10 {
  margin-bottom: var(--pf-margin-10);
}

.mb-11 {
  margin-bottom: var(--pf-margin-11);
}

.mb-12 {
  margin-bottom: var(--pf-margin-12);
}

.mb-13 {
  margin-bottom: var(--pf-margin-13);
}

.mb-14 {
  margin-bottom: var(--pf-margin-14);
}

.mb-15 {
  margin-bottom: var(--pf-margin-15);
}

.mb-16 {
  margin-bottom: var(--pf-margin-16);
}

.mb-n1 {
  margin-bottom: var(--pf-negative-margin-1);
}

.mb-n2 {
  margin-bottom: var(--pf-negative-margin-2);
}

.mb-n3 {
  margin-bottom: var(--pf-negative-margin-3);
}

.mb-n4 {
  margin-bottom: var(--pf-negative-margin-4);
}

.mb-n5 {
  margin-bottom: var(--pf-negative-margin-5);
}

.mb-n6 {
  margin-bottom: var(--pf-negative-margin-6);
}

.mb-n7 {
  margin-bottom: var(--pf-negative-margin-7);
}

.mb-n8 {
  margin-bottom: var(--pf-negative-margin-8);
}

.mb-n9 {
  margin-bottom: var(--pf-negative-margin-9);
}

.mb-n10 {
  margin-bottom: var(--pf-negative-margin-10);
}

.mb-n11 {
  margin-bottom: var(--pf-negative-margin-11);
}

.mb-n12 {
  margin-bottom: var(--pf-negative-margin-12);
}

.mb-n13 {
  margin-bottom: var(--pf-negative-margin-13);
}

.mb-n14 {
  margin-bottom: var(--pf-negative-margin-14);
}

.mb-n15 {
  margin-bottom: var(--pf-negative-margin-15);
}

.mb-n16 {
  margin-bottom: var(--pf-negative-margin-16);
}

.mt-auto {
  margin-top: var(--pf-margin-auto);
}

.mt-0 {
  margin-top: var(--pf-margin-0);
}

.mt-1 {
  margin-top: var(--pf-margin-1);
}

.mt-2 {
  margin-top: var(--pf-margin-2);
}

.mt-3 {
  margin-top: var(--pf-margin-3);
}

.mt-4 {
  margin-top: var(--pf-margin-4);
}

.mt-5 {
  margin-top: var(--pf-margin-5);
}

.mt-6 {
  margin-top: var(--pf-margin-6);
}

.mt-7 {
  margin-top: var(--pf-margin-7);
}

.mt-8 {
  margin-top: var(--pf-margin-8);
}

.mt-9 {
  margin-top: var(--pf-margin-9);
}

.mt-10 {
  margin-top: var(--pf-margin-10);
}

.mt-11 {
  margin-top: var(--pf-margin-11);
}

.mt-12 {
  margin-top: var(--pf-margin-12);
}

.mt-13 {
  margin-top: var(--pf-margin-13);
}

.mt-14 {
  margin-top: var(--pf-margin-14);
}

.mt-15 {
  margin-top: var(--pf-margin-15);
}

.mt-16 {
  margin-top: var(--pf-margin-16);
}

.mt-n1 {
  margin-top: var(--pf-negative-margin-1);
}

.mt-n2 {
  margin-top: var(--pf-negative-margin-2);
}

.mt-n3 {
  margin-top: var(--pf-negative-margin-3);
}

.mt-n4 {
  margin-top: var(--pf-negative-margin-4);
}

.mt-n5 {
  margin-top: var(--pf-negative-margin-5);
}

.mt-n6 {
  margin-top: var(--pf-negative-margin-6);
}

.mt-n7 {
  margin-top: var(--pf-negative-margin-7);
}

.mt-n8 {
  margin-top: var(--pf-negative-margin-8);
}

.mt-n9 {
  margin-top: var(--pf-negative-margin-9);
}

.mt-n10 {
  margin-top: var(--pf-negative-margin-10);
}

.mt-n11 {
  margin-top: var(--pf-negative-margin-11);
}

.mt-n12 {
  margin-top: var(--pf-negative-margin-12);
}

.mt-n13 {
  margin-top: var(--pf-negative-margin-13);
}

.mt-n14 {
  margin-top: var(--pf-negative-margin-14);
}

.mt-n15 {
  margin-top: var(--pf-negative-margin-15);
}

.mt-n16 {
  margin-top: var(--pf-negative-margin-16);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Variables declaration */
/* prettier-ignore */
.rdp-root {
  --rdp-accent-color: blue; /* The accent color used for selected days and UI elements. */
  --rdp-accent-background-color: #f0f0ff; /* The accent background color used for selected days and UI elements. */
  --rdp-font-family: system-ui; /* The font family used by the calendar. Note that `inherit`does not work here. */

  --rdp-day-font: inherit; /* The font used for the day cells. */
  --rdp-day-height: 2.75rem; /* The height of the day cells. */
  --rdp-day-width: 2.75rem; /* The width of the day cells. */
  
  --rdp-day_button-border-radius: 100%; /* The border radius of the day cells. */
  --rdp-day_button-border: 2px solid transparent; /* The border of the day cells. */
  --rdp-day_button-height: var(--rdp-day-width); /* The height of the day cells. */
  --rdp-day_button-width: var(--rdp-day-height); /* The width of the day cells. */
  
  --rdp-selected-border: 2px solid var(--rdp-accent-color); /* The border of the selected days. */
  --rdp-selected-font: bold large var(--rdp-font-family); /* The font of the selected days. */
  --rdp-disabled-opacity: 0.5; /* The opacity of the disabled days. */
  --rdp-outside-opacity: 0.75; /* The opacity of the days outside the current month. */
  --rdp-today-color: var(--rdp-accent-color); /* The color of the today's date. */
  
  --rdp-dropdown-gap: 0.5rem;/* The gap between the dropdowns used in the month captons. */
  
  --rdp-month_caption-font: bold larger var(--rdp-font-family); /* The font of the month caption. */
  --rdp-months-gap: 2rem; /* The gap between the months in the multi-month view. */
  
  --rdp-nav_button-disabled-opacity: 0.5; /* The opacity of the disabled navigation buttons. */
  --rdp-nav_button-height: 2.25rem; /* The height of the navigation buttons. */
  --rdp-nav_button-width: 2.25rem; /* The width of the navigation buttons. */
  --rdp-nav-height: 2.75rem; /* The height of the navigation bar. */
  
  --rdp-range_middle-background-color: var(--rdp-accent-background-color); /* The color of the background for days in the middle of a range. */
  --rdp-range_middle-font: normal medium var(--rdp-font-family); /* The font for days in the middle of a range. */
  --rdp-range_middle-foreground-color: white; /* The font for days in the middle of a range. */
  --rdp-range_middle-color: inherit;/* The color of the range text. */
  
  --rdp-range_start-color: white; /* The color of the range text. */
  --rdp-range_start-background: linear-gradient(var(--rdp-gradient-direction), transparent 50%, var(--rdp-range_middle-background-color) 50%); /* Used for the background of the start of the selected range. */
  --rdp-range_start-date-background-color: var(--rdp-accent-color); /* The background color of the date when at the start of the selected range. */
  
  --rdp-range_end-background: linear-gradient(var(--rdp-gradient-direction), var(--rdp-range_middle-background-color) 50%, transparent 50%); /* Used for the background of the end of the selected range. */
  --rdp-range_end-color: white;/* The color of the range text. */
  --rdp-range_end-date-background-color: var(--rdp-accent-color); /* The background color of the date when at the end of the selected range. */
  
  --rdp-week_number-border-radius: 100%; /* The border radius of the week number. */
  --rdp-week_number-border: 2px solid transparent; /* The border of the week number. */
  --rdp-week_number-font: 400 small var(--rdp-font-family); /* The font of the week number cells. */
  --rdp-week_number-height: var(--rdp-day-height); /* The height of the week number cells. */
  --rdp-week_number-opacity: 0.75; /* The opacity of the week number. */
  --rdp-week_number-width: var(--rdp-day-width); /* The width of the week number cells. */
  --rdp-weeknumber-text-align: center; /* The text alignment of the weekday cells. */

  --rdp-weekday-font: 500 smaller var(--rdp-font-family); /* The font of the weekday. */
  --rdp-weekday-opacity: 0.75; /* The opacity of the weekday. */
  --rdp-weekday-padding: 0.5rem 0rem; /* The padding of the weekday. */
  --rdp-weekday-text-align: center; /* The text alignment of the weekday cells. */

  --rdp-gradient-direction: 90deg;
}

.rdp-root[dir="rtl"] {
  --rdp-gradient-direction: -90deg;
}

/* Root of the component. */
.rdp-root {
  position: relative; /* Required to position the navigation toolbar. */
  box-sizing: border-box;
}

.rdp-root * {
  box-sizing: border-box;
}

.rdp-day {
  width: var(--rdp-day-width);
  height: var(--rdp-day-height);
  font: var(--rdp-day-font);
  text-align: center;
}

.rdp-day_button {
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font: inherit;
  color: inherit;
  justify-content: center;
  align-items: center;
  display: flex;

  width: var(--rdp-day_button-width);
  height: var(--rdp-day_button-height);
  border: var(--rdp-day_button-border);
  border-radius: var(--rdp-day_button-border-radius);
}

.rdp-day_button:disabled {
  cursor: revert;
}

.rdp-caption_label {
  z-index: 1;

  position: relative;
  display: inline-flex;
  align-items: center;

  white-space: nowrap;
  border: 0;
}

.rdp-button_next,
.rdp-button_previous {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font: inherit;
  color: inherit;
  -moz-appearance: none;
  -webkit-appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  appearance: none;

  width: var(--rdp-nav_button-width);
  height: var(--rdp-nav_button-height);
}

.rdp-button_next:disabled,
.rdp-button_previous:disabled {
  cursor: revert;

  opacity: var(--rdp-nav_button-disabled-opacity);
}

.rdp-chevron {
  display: inline-block;
  fill: var(--rdp-accent-color);
}

.rdp-root[dir="rtl"] .rdp-nav .rdp-chevron {
  transform: rotate(180deg);
}

.rdp-root[dir="rtl"] .rdp-nav .rdp-chevron {
  transform: rotate(180deg);
  transform-origin: 50%;
}

.rdp-dropdowns {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: var(--rdp-dropdown-gap);
}
.rdp-dropdown {
  z-index: 2;

  /* Reset */
  opacity: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: inherit;
  border: none;
  line-height: inherit;
}

.rdp-dropdown_root {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.rdp-dropdown_root[data-disabled="true"] .rdp-chevron {
  opacity: var(--rdp-disabled-opacity);
}

.rdp-month_caption {
  display: flex;
  align-content: center;
  height: var(--rdp-nav-height);
  font: var(--rdp-month_caption-font);
}

.rdp-months {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: var(--rdp-months-gap);
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.rdp-month_grid {
  border-collapse: collapse;
}

.rdp-nav {
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;

  display: flex;
  align-items: center;

  height: var(--rdp-nav-height);
}

.rdp-weekday {
  opacity: var(--rdp-weekday-opacity);
  padding: var(--rdp-weekday-padding);
  font: var(--rdp-weekday-font);
  text-align: var(--rdp-weekday-text-align);
  text-transform: var(--rdp-weekday-text-transform);
}

.rdp-week_number {
  opacity: var(--rdp-week_number-opacity);
  font: var(--rdp-week_number-font);
  height: var(--rdp-week_number-height);
  width: var(--rdp-week_number-width);
  border: var(--rdp-week_number-border);
  border-radius: var(--rdp-week_number-border-radius);
  text-align: var(--rdp-weeknumber-text-align);
}

/* DAY MODIFIERS */
.rdp-today:not(.rdp-outside) {
  color: var(--rdp-today-color);
}

.rdp-selected {
  font: var(--rdp-selected-font);
}

.rdp-selected .rdp-day_button {
  border: var(--rdp-selected-border);
}

.rdp-outside {
  opacity: var(--rdp-outside-opacity);
}

.rdp-disabled {
  opacity: var(--rdp-disabled-opacity);
}

.rdp-hidden {
  visibility: hidden;
  color: var(--rdp-range_start-color);
}

.rdp-range_start {
  background: var(--rdp-range_start-background);
}

.rdp-range_start .rdp-day_button {
  background-color: var(--rdp-range_start-date-background-color);
  color: var(--rdp-range_start-color);
}

.rdp-range_middle {
  background-color: var(--rdp-range_middle-background-color);
  font: var(--rdp-range_middle-font);
}

.rdp-range_middle .rdp-day_button {
  border-color: transparent;
  border: unset;
  border-radius: unset;
  color: var(--rdp-range_middle-color);
}

.rdp-range_end {
  background: var(--rdp-range_end-background);
  color: var(--rdp-range_end-color);
}

.rdp-range_end .rdp-day_button {
  color: var(--rdp-range_start-color);
  background-color: var(--rdp-range_end-date-background-color);
}

.rdp-range_start.rdp-range_end {
  background: revert;
}

.rdp-focusable {
  cursor: pointer;
}
